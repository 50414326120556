<template>
  <div class="flex">
    <div class>
      <div class="over-border border-brand-red border-dashed border-2 p-1">
      <div
        v-if="!media"
        @drop.prevent="onDrop"
        @dragover.prevent
        @dragenter="ondrag = true"
        @dragend="ondrag = false"
        @dragleave="ondrag = false"

        class="bg-grey-lighter drop-down dragged relative overflow-hidden  flex flex-col items-center justify-center"
      >
      <div v-if="progress" class="w-full h-full z-50 flex justify-center items-center absolute bg-grey-lighter" >
          <h4 class="text-4xl text-brand">Загрузка {{progress}}%</h4>
      </div>

        <div class="upload relative overflow-hidden">
          <img class src="@/icons/path.svg" alt>
          <input type="file" @change="inputUpload" name id class="opacity-0 absolute pin cursor-pointer">
        </div>
        <p>Выберите файл для загрузки</p>
        <p>или перетащите его мышью</p>
      </div>
      <AnimationThumb v-else :media="media" :key="key">
        </AnimationThumb>
      </div>
      <button
        @click="mediaDelete"
        :class="[media ? 'opacity-1' : 'opacity-0 disabled cursor-default']"
        class="text-white bg-brand-red mx-auto px-6 py-2 self-end mt-2 rounded border-2 mt-2 border-brand-red"
      >Удалить</button>
    </div>

    <div class="animation-typing w-1/3 px-4 flex flex-col">
      <label class="font-sans font-semibold" for>Название анимации</label>
      <input
        v-model="name"
        type="text"
        class="w-full p-2 border border-brand-light mb-2 outline-none"
      >

      <div v-if="!animation_type.includes('result') || animation_type == 'tour-selection-result' || animation_type == 'multyplayer-results'">
        <label class="font-sans font-semibold" for>Условие для анимации</label>
        <select v-model="select_condition" class="border-solid border-brand-light p-2 border w-full mb-2 outline-none">
          <option class="" v-for="(option, index) in options" :key="index" v-text="option.text" :value="option.value">Выбрать условие</option>
        </select>

        <label v-if="select_condition === 'link'" class="font-sans font-semibold" for>Ссылка</label>
        <input v-if="select_condition === 'link'"
               v-model="animation_link"
               placeholder="Вставьте ссылку"
               type="text"
               class="w-full p-2 border border-brand-light mb-2 outline-none"
        >
      </div>

      <button
        :disabled="select_condition==='link' && !animation_link"
        @click="saveAnimationSettings"
        class="text-white bg-brand px-6 py-2 w-2/3 self-end mt-auto rounded border-2 border-brand"
      >Сохранить</button>
    </div>
  </div>
</template>

<script>
import AnimationThumb from '@/components/AnimationThumb'
import axios from 'axios'
const uuidv4 = require('uuid/v4')

export default {
  name: 'animation-type',
  components: {
    AnimationThumb,
  },
  props: ['model_name', 'model_id', 'media', 'collection_name', 'cell_settings', 'item_name', 'animation_type'],
  created () {
    if (this.media) this.ext = this.media.split('.').pop()
    this.select_condition = this.cell_settings.type
    this.name = this.item_name
    if (this.cell_settings.properties) {
      this.animation_link = this.cell_settings.properties.link
    }
  },
  data () {
    return {
      // media: false,
      ext: '',
      animation_link: '',
      loading: false,
      file: '',
      name: '',
      select_condition: 'click_play',
      ondrag: false,
      key: 105,
      options: [
        { value: 'click_play', text: 'Стоп в конце + клик' },
        { value: 'link', text: 'Переход по ссылке' },
        { value: '3_seconds_skip', text: 'Пропуск видео по клику через 3 секунды' },
        { value: 'auto_next_slide', text: 'Автоматический переход' },

      ],
      progress: 0,
    }
  },
  methods: {
    updatePorg (ev) {
      this.progress = ev
    },
    onDrop (e) {
      this.ondrag = false
      e.preventDefault()
      this.file = e.dataTransfer.files[0]
      this.upload()
    },
    inputUpload (e) {
      this.file = e.target.files[0]
      this.upload()
    },
    upload () {
      this.loading = true
      let data = new FormData()
      data.append('collection_name', this.collection_name)
      data.append('item', this.collection_name)
      data.append('info', this.file)
      axios
        .post(`/api/${this.model_name}/${this.model_id}/media`, data, { headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length')
          this.updatePorg(Math.round((progressEvent.loaded * 100) / totalLength))
        } })
        .then(({ data }) => {
          this.$nextTick(() => {
            this.$emit('input', data.link)
          })
        })
        .catch(error => {
          let err = error.data.errors.info
          alert(err[0])
        })
        .then(() => {
          this.loading = false
        })
    },
    mediaDelete () {
      axios
        .delete(
          `/api/${this.model_name}/${this.model_id}/media/${
            this.collection_name
          }`
        )
        .then(() => this.$emit('input', null))
    },
    saveAnimationSettings () {
      this.$emit('settings_change', {
        name: this.name,
        type: this.select_condition,
        link: this.animation_link ? this.animation_link : '',
      })
      this.modalHolder.close()
    },
  },
  watch: {
    media: {
      handler (n, o) {
        if (n.length) this.key = uuidv4(), this.progress = 0
      },
      deep: true,
    },
  },
}
</script>

<style lang="less" scoped>
.drop-down {
  width: 500px;
  height: auto;
  min-height: 281px;
}
.upload {
  width: 80px;
  height: 60px;
}
.dragged{
  transition: all .3s ease;
}
.over-border {
  object-position: center center;
}
button:disabled {
  @apply bg-brand-grey;
}
.downloader {
  object-fit: cover;
}
</style>
