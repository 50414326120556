<template>
    <div class="flex justify-between text-center -mx-2 mt-4 p-4">
        <router-link v-if="toursCount" v-for="(tour, index) in toursCount" :key="tour"
                     :to="{name: 'game_questions', params: {id: id, tour: getQuestionStandart(tour, index), serial_number: getSerialNumber(tour, index)}}"
                     class="text-white no-underline px-8 py-2 bg-brand rounded mx-3 hover:bg-brand-light whitespace-no-wrap"
        >{{getSerialNumber(tour, index)}} тур
        </router-link>
    </div>
</template>

<script>
export default {
  name: 'GameTourSwitch',
  props: ['id', 'toursCount', 'template'],
  methods: {
    getQuestionStandart (tourNumber, index) {
      if (this.template) {
        if (this.template.start_from_zero) tourNumber = index
        let tours = this.template.rules.tours
        return tours.filter(tour => tour.id === tourNumber)[0].tour_id
      }
      return tourNumber
    },
    getSerialNumber (tourNumber, index) {
      if (this.template && this.template.start_from_zero) {
        return index
      }
      return tourNumber
    },
  },
}
</script>

<style scoped>
    @tailwind utilities;

    .router-link-active {
        @apply bg-brand-light;
    }

</style>
