<template>
  <div v-if="loaded" class="container mx-auto relative">
    <div class="flex px-8 -mb-4 font-semibold">
      <div v-if="mainType" class="mr-1">{{ mainType }}</div>
      <div v-if="subType" class="mr-1">/ {{ subType }}</div>
      <div v-if="topic" class="mr-1">/ {{ topic }}</div>
    </div>
    <div
      class="absolute w-full h-full top-0 bottom-0 right-0 left-0 z-10"
      v-if="isFreelancer"
    ></div>
    <div class="border-2 rounded shadow-md p-6 m-8 flex flex-col relative">
      <div class="flex justify-start">
        <div class="ml-2 flex-1">
          <div class="flex justify-start items-center">
            <div class="justify-start border-brand">
              <div>
                <select
                  v-model="question.type"
                  :readonly="disabled"
                  :disabled="disabled"
                  class="p-1 border-solid border-brand-light border"
                >
                  <option
                    v-for="(quest, q_key) in game_types"
                    :key="q_key"
                    :class="{ 'bg-pink': !quest.is_implemented }"
                    :value="quest.slide_component"
                  >
                    {{
                      quest.is_implemented ? quest.name : `- ${quest.name} -`
                    }}
                  </option>
                </select>
              </div>
            </div>
            <div class="w-32 flex justify-start items-center ml-4">
              <div><label class="font-semibold mr-2">N пакета</label></div>
              <input
                type="text"
                v-model="question.pack_number"
                :disabled="disabled"
                class="p-1 border-solid border-brand-light border w-1/3 rounded"
              />
            </div>
            <div class="flex justify-center items-center ml-4">
              <div><label class="font-semibold mr-2">Тур</label></div>
              <select
                v-model="question.tour_id"
                class="w-10 outline-none border-2 rounded"
                :disabled="disabled"
              >
                <option :value="1">1</option>
                <option :value="2">2</option>
                <option :value="3">3</option>
                <option :value="4">4</option>
                <option :value="5">5</option>
                <option :value="6">6</option>
                <option :value="7">7</option>
              </select>
            </div>
            <div
              v-if="(isAuthor || isCorrector || isEditor) && !isFreelancer"
              class="ml-auto"
            >
              <button
                type="button"
                @click="dis = !dis"
                class="text-white no-underline bg-brand py-2 px-4 rounded ml-3"
              >
                Редактировать
              </button>
              <a
                :href="changedUrl + `/edit.html?questions=${questionId}`"
                target="_blank "
                class="text-white no-underline bg-brand py-2 px-4 rounded ml-3"
              >
                Верстка
              </a>
            </div>
            <!--</a>a :href="`https://game.local/preview.html?questions=${questionId}`" target="_blank"-->
            <!--class="text-white no-underline bg-brand py-2 px-4 rounded ml-3"> Верстка-->
            <!--</a>-->
          </div>

          <div v-if="qustionType !== 'handout'" class="flex -mx-2">
            <div
              v-if="
                qustionType === '3_facts_about' || qustionType === '3_strings'
              "
              class="w-1/3 mx-2 my-4"
            >
              <label class="text-brand-dark font-semibold">Факты</label>
              <div class="mt-3" v-for="(fact, idx) in facts" :key="idx">
                <div class="pt-1 pb-3">
                  <textarea
                    rows="3"
                    :disabled="disabled"
                    v-model="fact.text"
                    class="resize-none border-2 flex w-full p-2"
                    placeholder="Текст"
                  />
                </div>
              </div>
              <label class="text-brand-dark font-semibold">Вопрос</label>
              <div class="pt-1 pb-3">
                <textarea
                  rows="2"
                  :disabled="disabled"
                  v-model="question.question.question"
                  class="resize-none border-2 flex w-full p-2"
                  placeholder="Текст"
                ></textarea>
              </div>
            </div>
            <div v-else class="mx-2 w-1/3">
              <label
                class="align-center justify-center font-semibold block my-4"
                >Вопрос</label
              >
              <textarea
                v-if="
                  qustionType !== '4_variant_blitz' &&
                  qustionType !== '4_variant_music_blitz'
                "
                class="p-2 border-2 resize-none w-full inputer"
                rows="7"
                :disabled="disabled"
                v-model="question.question.text"
              ></textarea>
              <div
                v-if="
                  qustionType === '4_variant_blitz' ||
                  qustionType === '4_variant_music_blitz'
                "
              >
                <textarea
                  :disabled="disabled"
                  rows="2"
                  v-model="question.question.question"
                  class="resize-none border-2 flex w-full p-2"
                  placeholder="Вопрос"
                ></textarea>
                <input
                  :disabled="disabled"
                  type="text"
                  class="border-2 w-full flex py-2 px-1 mt-2"
                  v-for="(variant, idx) in variants"
                  :key="idx"
                  v-model="variant.text"
                  :placeholder="`Вариант ${idx + 1}`"
                />
              </div>
            </div>

            <div class="mx-2 w-1/3">
              <label
                class="align-center justify-center font-semibold block my-4"
                >Подвязка</label
              >
              <textarea
                v-if="
                  qustionType !== '4_variant_blitz' &&
                  qustionType !== '4_variant_music_blitz'
                "
                class="p-2 border-2 resize-none w-full inputer"
                rows="7"
                :disabled="disabled"
                v-model="question.supply.text"
              ></textarea>
              <div
                v-if="
                  qustionType === '4_variant_blitz' ||
                  qustionType === '4_variant_music_blitz'
                "
              >
                <textarea
                  :disabled="disabled"
                  v-model="question.supply.text"
                  class="p-2 border-2 resize-none w-full inputer"
                  rows="7"
                  :placeholder="'Текст'"
                ></textarea>
              </div>
            </div>
            <div class="mx-2 w-1/3">
              <div class="">
                <label
                  class="align-center justify-center font-semibold block my-4"
                  >Ответ</label
                >
                <textarea
                  v-if="
                    qustionType !== '4_variant_blitz' &&
                    qustionType !== '4_variant_music_blitz'
                  "
                  class="small p-2 border-2 resize-none w-full inputer"
                  rows="4"
                  :disabled="disabled"
                  v-model="question.answer.text"
                ></textarea>
                <div
                  class="radio-group"
                  v-if="
                    qustionType === '4_variant_blitz' ||
                    qustionType === '4_variant_music_blitz'
                  "
                >
                  <input
                    :disabled="disabled"
                    type="radio"
                    :id="`${question.id}-1`"
                    name="selector"
                    :value="1"
                    v-model="question.answer.text"
                  />
                  <label
                    :for="`${question.id}-1`"
                    :class="{
                      'bg-brand text-white': question.answer.text === 1,
                    }"
                    class="
                      ml-2
                      py-2
                      px-4
                      cursor-pointer
                      radio-element
                      text-center
                      select-none
                    "
                    >1</label
                  >
                  <input
                    :disabled="disabled"
                    type="radio"
                    :id="`${question.id}-2`"
                    name="selector"
                    :value="2"
                    v-model="question.answer.text"
                  />
                  <label
                    :for="`${question.id}-2`"
                    :class="{
                      'bg-brand text-white': question.answer.text === 2,
                    }"
                    class="
                      ml-2
                      py-2
                      px-4
                      cursor-pointer
                      radio-element
                      text-center
                      select-none
                    "
                    >2</label
                  >
                  <input
                    :disabled="disabled"
                    type="radio"
                    :id="`${question.id}-3`"
                    name="selector"
                    :value="3"
                    v-model="question.answer.text"
                  />
                  <label
                    :for="`${question.id}-3`"
                    :class="{
                      'bg-brand text-white': question.answer.text === 3,
                    }"
                    class="
                      ml-2
                      py-2
                      px-4
                      cursor-pointer
                      radio-element
                      text-center
                      select-none
                    "
                    >3</label
                  >
                  <input
                    :disabled="disabled"
                    type="radio"
                    :id="`${question.id}-4`"
                    name="selector"
                    :value="4"
                    v-model="question.answer.text"
                  />
                  <label
                    :for="`${question.id}-4`"
                    :class="{
                      'bg-brand text-white': question.answer.text === 4,
                    }"
                    class="
                      ml-2
                      py-2
                      px-4
                      cursor-pointer
                      radio-element
                      text-center
                      select-none
                    "
                    >4</label
                  >
                </div>
              </div>
              <div class="pt-1 pb-3"></div>

              <div class="">
                <label class="font-semibold">Файлы</label>
                <div class="pt-4">
                  <add-media
                    class="media pb-3"
                    v-for="(media, key) in mediaCount"
                    :key="key"
                    :item="key"
                    :dis="dis"
                    :baseUrl="`/api/questions/${questionId}`"
                    v-model="question.media[key]"
                  ></add-media>
                </div>
              </div>
            </div>
          </div>

          <div class="flex -mx-2" v-if="qustionType === 'handout'">
            <div class="w-1/3 mx-2 my-2">
              <label class="text-brand-dark font-semibold">Вопрос</label>
              <div class="mt-1">
                <div class="pt-1 pb-3">
                  <textarea
                    :disabled="disabled"
                    rows="3"
                    v-model="question.question.question"
                    class="resize-none border-2 flex w-full p-2"
                    placeholder="Текст"
                  />
                </div>
              </div>
              <div class="pt-1 pb-3">
                <div class="mt-3" v-for="(razdat, idx) in handout" :key="idx">
                  <input
                    :disabled="disabled"
                    type="text"
                    class="border-2 w-full flex py-2 px-1"
                    v-model="razdat.text"
                    :placeholder="`Вариант ${idx + 1}`"
                  />
                </div>
              </div>
              <div class="">
                <label class="font-semibold">Файлы</label>
                <div class="">
                  <add-media
                    class="media"
                    :key="0"
                    :item="0"
                    :dis="dis"
                    :collection_name="'question'"
                    :baseUrl="`/api/questions/${questionId}`"
                    v-model="question.media.question"
                  ></add-media>
                </div>
              </div>
            </div>

            <div class="w-2/3 px-1 flex flex-wrap">
              <div
                class="flex flex-col w-1/2 px-1"
                v-for="(id) in media_collection"
                :key="id"
              >
                <div>
                  <label
                    class="align-center justify-center font-semibold block my-2"
                    >Подвязка {{ id + 1 }}</label
                  >
                  <textarea
                    :disabled="disabled"
                    rows="3"
                    v-model="question.supply.handout[id].text"
                    class="resize-none border-2 flex w-full p-2"
                    placeholder="Текст"
                  />
                </div>
                <div class="">
                  <label
                    class="align-center justify-center font-semibold block my-2"
                    >Ответ {{ id + 1 }}</label
                  >
                  <textarea
                    :disabled="disabled"
                    rows="2"
                    v-model="question.answer.handout[id].text"
                    class="resize-none border-2 flex w-full p-2"
                    placeholder="Текст"
                  />
                </div>
                <div class="">
                  <label class="font-semibold">Файлы</label>
                  <div class="">
                    <add-media
                      class="media"
                      :key="`${id}_supply`"
                      :supply_id="id"
                      :item="0"
                      :dis="dis"
                      :type="question.type"
                      :collection_name="`${id + 1}_supply`"
                      :baseUrl="`/api/questions/${questionId}`"
                      v-model="media_collection[id].link_supply"
                    ></add-media>
                    <add-media
                      class="media"
                      :key="`${id}_answer`"
                      :id="`${id}_answer`"
                      :supply_id="id"
                      :item="0"
                      :dis="dis"
                      :collection_name="`${id + 1}_answer`"
                      :baseUrl="`/api/questions/${questionId}`"
                      v-model="media_collection[id].link_answer"
                    ></add-media>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex pt-4 text-brand">
            <p class="inline-block">Дополнительные характеристики</p>
            <label
              class="ml-auto"
              v-if="question.user"
              v-text="`Автор вопроса: ${question.user.name}`"
            ></label>
            <label class="ml-auto" v-else v-text="'Автор не указан'"></label>
          </div>
          <additional-features
            class="no-underline pt-4 text-brand-grey"
            v-if="question"
            :questions="question"
            :dis="dis"
          ></additional-features>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddMedia from './add-media'
import debounce from 'lodash/debounce'
import axios from 'axios'
import AdditionalFeatures from './additional-features'

export default {
  props: ['value'],
  components: { AdditionalFeatures, AddMedia },
  name: 'Question',
  data () {
    return {
      question: null,
      game_types: {},
      dis: true,
      loaded: false,
      gameCategories: [],
      facts: [],
      variants: [],
      variant_blitz: [{ text: '' }, { text: '' }, { text: '' }, { text: '' }],
      media_collection: [],
      template_facts: [{ text: '' }, { text: '' }, { text: '' }],
      handout: [],
      handout_supply_answer: [],
      template_handout: [
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
      ],
      template_handout_supply_answer: [
        { supply: { text: '' }, answer: { text: '' } },
        { supply: { text: '' }, answer: { text: '' } },
        { supply: { text: '' }, answer: { text: '' } },
        { supply: { text: '' }, answer: { text: '' } },
        { supply: { text: '' }, answer: { text: '' } },
        { supply: { text: '' }, answer: { text: '' } },
        { supply: { text: '' }, answer: { text: '' } },
        { supply: { text: '' }, answer: { text: '' } },
      ],
    }
  },
  created () {
    this.question = this.value
    this.loaded = true
    axios.get(`/api/questions/slide_type`).then((data) => {
      this.game_types = data.data
      this.loaded = true
    })
    this.getGameCategories()
    if (
      this.qustionType === '3_facts_about' ||
      this.qustionType === '3_strings'
    ) {
      this.facts = this.question.question.facts
        ? this.question.question.facts
        : this.template_facts
    }
    if (
      this.question.type === '4_variant_blitz' ||
      this.question.type === '4_variant_music_blitz'
    ) {
      this.variants = this.question.question.variants
        ? this.question.question.variants
        : this.variant_blitz
    }
    if (
      this.qustionType !== 'handout' &&
      !this.question.supply.text &&
      !this.question.answer.text
    ) {
      this.question.supply = { text: '' }
      this.question.answer = { text: '' }
    }
    if (this.qustionType === 'handout') {
      this.handout = this.question.question.handout
        ? this.question.question.handout
        : this.template_handout
      this.handout_supply_answer = this.allSupplyAnswer
      if (!this.media_collection[0]) {
        this.createCollection()
      }
      this.addMediaToCollection()
    }
  },
  computed: {
    changedUrl () {
      return (
        window.APP.API_QUESTION_URL || process.env.VUE_APP_API_QUESTION_URL
      )
    },
    s_type () {
      console.log(this.question.type)
      return this.question.type.media
    },

    mediaCount () {
      return this.currentTypes.media
    },
    qustionType () {
      return this.question.type
    },
    currentTypes () {
      if (!this.question) return {}
      return this.game_types[this.question.type] || {}
    },

    questionId () {
      return this.$route.params.questionId
    },

    disabled () {
      return this.dis
    },
    mainType () {
      return this.question.main_type
    },
    subType () {
      return this.question.sub_type
    },
    topic () {
      return this.question.topic
    },
    allSupplyAnswer () {
      const allSupplyAnswer = []
      if (!this.question.supply.handout) {
        this.question.supply.handout = []
      }
      if (!this.question.answer.handout) {
        this.question.answer.handout = []
      }
      for (let i = 0; i < this.template_handout_supply_answer.length; i++) {
        allSupplyAnswer.push({
          supply: this.question.supply.handout[i]
            ? this.question.supply.handout[i]
            : this.template_handout_supply_answer[i].supply,
          answer: this.question.answer.handout[i]
            ? this.question.answer.handout[i]
            : this.template_handout_supply_answer[i].answer,
        })
      }
      return allSupplyAnswer
    },
  },

  methods: {
    saver: debounce(function () {
      this.$emit('save', this.question)
    }, 500),
    fetcher: debounce(function () {
      this.$emit('fetch')
    }, 500),
    getGameCategories () {
      axios.get(`/api/game-categories`).then((data) => {
        this.gameCategories = data.data
      })
    },
    concatQuestionText () {
      if (
        this.qustionType === '3_facts_about' ||
        this.qustionType === '3_strings'
      ) {
        this.question.question.facts = this.facts
        let textString = ''
        this.facts.forEach((item) => {
          textString = textString + item.text + '. '
        })
        this.question.question.text =
          textString + this.question.question.question
      }
      if (this.question.type === 'handout') {
        this.question.question.handout = this.handout
        let textString = ''
        let textStringSupply = ''
        let textStringAnswer = ''
        this.handout.forEach((item) => {
          textString = `${textString + item.text}. `
        })
        this.question.question.text =
          textString + this.question.question.question
        if (!this.question.supply.handout) {
          this.question.supply.handout = []
        }
        if (!this.question.answer.handout) {
          this.question.answer.handout = []
        }
        this.handout_supply_answer.forEach((handout, id) => {
          this.question.answer.handout[id] = handout.answer
          this.question.supply.handout[id] = handout.supply
          textStringSupply = `${textStringSupply + handout.supply.text}. `
          textStringAnswer = `${textStringAnswer + handout.answer.text}. `
        })
        this.question.supply.text = textStringSupply
        this.question.answer.text = textStringAnswer
      }
      if (
        this.question.type === '4_variant_blitz' ||
        this.question.type === '4_variant_music_blitz'
      ) {
        this.question.question.variants = this.variants
        let textString = ''
        this.variants.forEach((item) => {
          textString = `${textString + item.text}. `
        })
        this.question.question.text =
          textString + this.question.question.question
      }
    },
    createCollection () {
      for (let i = 1; i <= 8; i++) {
        this.media_collection.push({
          id_supply: `${i}_supply`,
          link_supply: '',
          id_answer: `${i}_answer`,
          link_answer: '',
        })
      }
      if (
        this.question.type === '4_variant_blitz' ||
        this.question.type === '4_variant_music_blitz'
      ) {
        this.question.question.variants = this.variants
        let textString = ''
        this.variants.forEach((item) => {
          textString = `${textString + item.text}. `
        })
        this.question.question.text =
          textString + this.question.question.question
      }
    },
    addMediaToCollection () {
      Object.keys(this.question.media).forEach((item, key) => {
        const placement = item.split('_')[1]
        let mediaitem = {}
        if (placement === 'supply' && placement !== 'question') {
          mediaitem = this.media_collection.find(
            (element) => element.id_supply === item
          )
        }
        if (placement === 'answer' && placement !== 'question') {
          mediaitem = this.media_collection.find(
            (element) => element.id_answer === item
          )
        }
        const mediaIndex = Object.values(this.media_collection).indexOf(
          mediaitem
        )
        if (placement === 'supply' && placement !== 'question') {
          Object.values(this.media_collection)[mediaIndex].link_supply =
            Object.values(this.question.media)[key]
        }
        if (placement === 'answer' && placement !== 'question') {
          Object.values(this.media_collection)[mediaIndex].link_answer =
            Object.values(this.question.media)[key]
        }
      })
      this.addCollectionToMedia()
    },
    addCollectionToMedia () {
      this.media_collection.forEach((media, id) => {
        Object.values(this.question.media)[id] = media.link
      })
    },
  },
  watch: {
    currentType () {
      if (!this.currentType) {
        return
      }
      if (this.qustionType === 'handout') {
        this.handout = this.question.question.handout
          ? this.question.question.handout
          : this.template_handout
        this.handout_supply_answer = this.allSupplyAnswer
        delete this.question.question.facts
        this.concatQuestionText()
        if (!this.media_collection[0]) {
          this.createCollection()
        }
        this.addMediaToCollection()
      } else {
        this.question.supply = { text: '' }
        this.question.answer = { text: '' }
        if (
          this.qustionType !== '3_facts_about' &&
          this.qustionType !== '3_strings'
        ) {
          delete this.question.question.facts
          delete this.question.question.handout
        }
        if (
          this.qustionType === '3_facts_about' ||
          this.qustionType === '3_strings'
        ) {
          delete this.question.question.handout
          this.concatQuestionText()
        }
        while (this.question.media.length > this.currentType.media.length) {
          this.question.media.pop()
        }
        // todo validation
        while (this.question.media.length < this.currentType.media.length) {
          this.question.media.push(null)
        }
      }
    },
    question: {
      deep: true,
      immediate: false,
      handler () {
        this.saver()
      },
    },
    'question.question.question' () {
      this.concatQuestionText()
    },
    facts: {
      deep: true,
      handler () {
        this.concatQuestionText()
      },
    },
    variants: {
      deep: true,
      handler () {
        this.concatQuestionText()
      },
    },
    handout: {
      deep: true,
      handler () {
        this.concatQuestionText()
      },
    },
    media_collection: {
      deep: true,
      handler () {
        this.addCollectionToMedia()
      },
    },
    handout_supply_answer: {
      deep: true,
      handler () {
        this.concatQuestionText()
      },
    },
  },
}
</script>

<style scoped>
@tailwind utilities;
option {
  background: #6a6384;
  color: white;
}

input[type="radio"] {
  position: absolute;
  visibility: hidden;
  display: none;
}

.radio-element {
  display: inline-block;
  cursor: pointer;
  border-radius: 0.25rem;
  border-color: #6a6384;
  border-width: 2px;
  height: 2.1875rem;
  /*font-weight: bold;*/
  /*padding: 5px 20px;*/
  width: 4rem;
}

input[type="radio"]:checked + label {
  color: white;
  background-color: #6a6384;
  width: 4rem;
  height: 2.1875rem;
  border-width: 2px;
  border-color: #6a6384;
  border-radius: 0.25rem;
  /*background: $fg;*/
}

label + input[type="radio"] + label {
  /*border-left: solid $borderWidth $fg;*/
}

.radio-group {
  /*border: solid $borderWidth $fg;*/
  display: inline-block;
  height: 4rem;
  padding-top: 1rem;
  overflow: hidden;
}
</style>
