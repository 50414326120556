<template>
  <div class="media relative drop-down h-auto ">
    <slot name="body">
      <video v-if="ext==='mp4'" class="w-full h-full absolute pin" controls autoplay>
        <source :src="media">
      </video>
      <audio controls v-if="ext==='mp3'">
        <source :src="media" type="audio/mpeg">Your browser does not support the audio element.
      </audio>
      <img class="w-full h-full" v-if="ext==='jpg' || ext==='jpeg' || ext==='png' || ext==='gif'" :src="media">
    </slot>
  </div>
</template>

<script>
export default {
  name: 'AnmimationThumb',
  props: ['media'],
  created () {
    if (this.media) this.ext = this.media.split('.').pop()
  },
  data () {
    return {
      ext: '',
    }
  },
}
</script>

<style lang="less" scoped>

</style>
