<template>
    <on-click-outside :do="close">
        <div class="tag-select " :class="{ 'is-active': isOpen }">
            <button :ref="`button`" @click.prevent="switchPopper" type="button"
                    class="search-select-input pin-t flex z-0">
                <span class="z-0 pin-t flex" v-if="stateType === 'no_matter'">Не имеет значения</span>
                <span class="z-0 pin-t flex" v-else-if="stateType">Исключено</span>
                <span class="z-0 pin-t flex" v-else>Выбрано</span>
            </button>
            <div :ref="`panel`" v-show="isOpen" class="tag-select-panel shadow-lg z-50"
                 style="background-color: #F4F4F4">
                <div class="flex flex-col rounded py-4 px-2 w-80 overflow-auto h-96">
                    <div><p>Тег</p></div>
                    <div class="flex pb-6" v-if="stateType !== 'no_matter'"><input type="checkbox" class="w-1/5" v-model="stateType">
                        <div class="ml-2 w-4/5">
                            <p v-if="!stateType || stateType === 'no_matter'">Выбрать отмеченных</p>
                            <p v-else>Исключить отмеченные</p>
                        </div>
                    </div>
                    <div class="">
                        <div v-for="(tag , index) in tags" :key="index" :value="tag.id" v-if="tag.id !== ''">
                            <div class="flex justify-start w-full overflow-y-auto" v-if="tag.type !== 'delimeter'">
                                <p class="p-2 flex mr-2 ml-auto w-4/5" v-if="tag.id !== ''">{{tag.name}}</p>
                                <check-box :checked="ids.indexOf(tag.id) !== -1" :dis="noMatter" @checked="addTagId(tag.id)"
                                           :id="`${tagName}-${questionId}-${tag.id}`" class="justify-end mr-auto w-1/5"
                                           v-if="tag.id !== ''">
                                </check-box>
                            </div>
                            <div class="w-full border-b border border-brand bg-brand my-1" v-else></div>
                        </div>
                    </div>

                </div>
                <hr>
                <div class="flex px-1 pb-1"><p class="p-2 flex mr-2 ml-auto w-4/5">Не имеет значения</p>
                    <no-matter-check-box v-model="noMatter" :id="`no-matter-${tagName}-${questionId}`"
                                         class="justify-end mr-auto w-1/5"></no-matter-check-box>
                </div>
            </div>
        </div>
    </on-click-outside>
</template>

<script>
import OnClickOutside from '../../components/OnClickOutside'
import CheckBox from './checkbox'
import NoMatterCheckBox from './no-matter-checkbox'
import Popper from 'popper.js'

export default {
  name: 'TagDescription',
  components: { NoMatterCheckBox, CheckBox, OnClickOutside },
  props: ['tags', 'tagName', 'questionId', 'selectedTags'],
  data () {
    let state = this.selectedTags.type === 'excluded'
    let noMatter = this.selectedTags.type !== 'selected' && this.selectedTags.type !== 'excluded'
    if (noMatter) {
      state = 'no_matter'
    }

    return {
      isOpen: false,
      currentTag: {},

      stateType: state,
      noMatter: noMatter,
      ids: this.selectedTags.ids || [],
    }
  },

  watch: {
    noMatter (val) {
      this.ids = []
      if (val) {
        this.stateType = 'no_matter'
      } else {
        this.stateType = false
      }
    },

    ids () {
     this.updateTags()
    },
    currentTag (val) {
      this.$emit('tagSelected', val)
    },
    stateType () {
      this.updateTags()
    },
  },
  beforeDestroy () {
    if (this.popper !== undefined) {
      this.popper.destroy()
    }
  },
  methods: {
    updateTags () {
      let obj = {}
      let state
      if (!this.stateType) {
        state = 'selected'
      } else if (this.stateType === true) {
        state = 'excluded'
      } else state = this.stateType

      obj[this.tagName] = {
        'type': state,
        'ids': this.ids,
      }
      this.currentTag = Object.assign({}, this.currentTag, obj)
    },
    setupPopper () {
      if (this.popper === undefined) {
        this.popper = new Popper(this.$refs[`button`], this.$refs[`panel`], {
          placement: 'bottom',
          positionFixed: true,
        })
      } else {
        this.popper.scheduleUpdate()
      }
    },
    switchPopper () {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$nextTick(() => {
          this.setupPopper()
        })
      }
    },
    close () {
      if (!this.isOpen) {
        return
      }
      this.isOpen = false
    },
    addTagId (tag_id) {
      if (this.ids.indexOf(tag_id) !== -1) {
        this.dropFromArray(tag_id)
      } else {
        this.ids.push(tag_id)
      }
    },
    dropFromArray (tag_id) {
      let index = this.ids.indexOf(tag_id)
      if (index !== -1) this.ids.splice(index, 1)
    },
  },
}
</script>
<style scoped>
    body {
        margin: 0;
        padding: 0;
        background: #544e68;
    }

    input[type="checkbox"] {
        width: 40px;
        height: 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: #544e68;
        outline: none;
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
        transition: 0.5s;
        position: relative;
    }

    input:checked[type="checkbox"] {
        background: #e17372;
    }

    input[type="checkbox"]::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: 0;
        left: 0;
        background: #fff;
        transform: scale(1.1);
        box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
        transition: 0.5s;
    }

    input:checked[type="checkbox"]::before {
        left: 20px;
    }
</style>
