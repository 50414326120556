<template>
    <div class="container mx-auto flex flex-col px-8 mx-4 h-full">
        <div class="container px-4 mx-auto flex justify-between h-1/2">
            <div class="rounded border-grey shadow-md text-left w-full h-full">
                <game-form @templateLoaded="setTourCount" @partyDetected="setPartyState" :partyHash="game.partyHash"></game-form>
                <game-tour-switch :id="id" :tours-count="tourCount" :template="template"></game-tour-switch>
                <router-view v-if="game" :game="game" :is-party="isParty"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import GameTourSwitch from './tour-switch'
import GameForm from './form'
import axios from 'axios'
export default {
  name: 'GameIndex',
  components: { GameForm, GameTourSwitch },
  computed: {
    id () {
      return this.$route.params.id
    },
    tourCount () {
      return this.template ? this.template.tour_count : 7
    },
  },
  data () {
    return {
      isParty: false,
      template: {},
      game: {},
    }
  },
  created () {
    axios.get(`/api/games/show/${this.$route.params.id}`).then((data) => {
      this.game = data.data
    })
  },
  methods: {
    setPartyState (state) {
      this.isParty = state
    },
    setTourCount (template) {
      this.template = template
    },
  },
}
</script>

<style scoped>
    @tailwind utilities;

    th {
        @apply text-left
        p-2 bg-white font-sans text-sm border-b border-r border-grey-light;
    }
</style>
