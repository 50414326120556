<template>
    <tr class="w-full hover:bg-grey-lightest my-1">
        <td>
            {{question.question_number}}
        </td>
        <td class="min-w-32">
            <div class="flex flex-col h-full">
                <div class="flex mb-auto py-2">
                    {{question.question ? question.question.question.text : "Выберите вопрос"}}
                </div>
                <div v-if="isParty && question.question" class="flex flex-col mt-auto">
                    <media :model_name="'questions'" :model_id="question.question.id"
                           :media="question.media.voiceActingQuestion ? question.media.voiceActingQuestion : ''"
                           :collection_name="'voiceActingQuestion'" @input="$emit('updated')"></media>
                </div>
            </div>
        </td>
        <td class="min-w-32">
            <div class="flex flex-col h-full">
                <div class="flex mb-auto py-2">
                    {{question.question ? question.question.comment_leading_question : "Выберите вопрос"}}
                </div>
                <div v-if="isParty && question.question" class="flex flex-col mt-auto">
                    <media :model_name="'questions'" :model_id="question.question.id"
                           :media="question.media.voiceActingRepeat ? question.media.voiceActingRepeat : ''"
                           :collection_name="'voiceActingRepeat'" @input="$emit('updated')"></media>
                </div>
            </div>
        </td>
        <td class="min-w-32">
            <div class="flex flex-col h-full">
                <div class="flex-1 mb-auto py-2">
                    {{question.question ? question.question.supply.text : "Выберите вопрос"}}
                </div>
                <div v-if="isParty && question.question" class="flex flex-col mt-auto">
                    <media :model_name="'questions'" :model_id="question.question.id"
                           :media="question.media.voiceActingSupply ? question.media.voiceActingSupply : ''"
                           :collection_name="'voiceActingSupply'" @input="$emit('updated')"></media>
                </div>
            </div>
        </td>
        <td class="min-w-32">
            <div class="flex flex-col h-full">
                <div class="flex-1 mb-auto py-2">
                    {{question.question ? question.question.answer.text : "Выберите вопрос"}}
                </div>
                <div v-if="isParty && question.question" class="flex flex-col mt-auto">
                    <media :model_name="'questions'" :model_id="question.question.id"
                           :media="question.media.voiceActingAnswer ? question.media.voiceActingAnswer : ''"
                           :collection_name="'voiceActingAnswer'" @input="$emit('updated')"></media>
                </div>
            </div>
        </td>
        <td class="min-w-32">
            <div class="flex flex-col h-full">
                <div class="flex-1 mb-auto py-2">
                    {{question.question ? question.question.id : "Выберите вопрос"}}
                </div>
            </div>
        </td>
        <td class="min-w-32">
            <div class="flex flex-col h-full">
                <div class="flex-1 mb-auto py-2">
                    {{question.question ? question.question.pack_number : "Выберите вопрос"}}
                </div>
            </div>
        </td>
        <td>
            <router-link v-if="question.question" :to="{name: 'question', params: {questionId: question.question.id}}" title="Посмотреть">
                <font-awesome-icon class="text-black text-base" :icon="['fas', 'eye']"></font-awesome-icon>
            </router-link>
        </td>
        <td class="border-r-0">
            <font-awesome-icon v-if="on_loading" class="spinner w-6 my-auto text-black text-base" :icon="['fas', 'spinner']"></font-awesome-icon>
            <toggle-modal v-else @updated="replace(question.id, $event)"
                          :options="toggleModalOptions">
            </toggle-modal>
        </td>
        <portal :to="`direct-replace-question-${question.id}`">
            <question-replace :question-id="question.id" :is-for-template="false" :tour-id="tour"
                              :category="isParty ? 'MozgoParty' : 'ClassicGame'"
                              :game="game"
                              :forever-replaced="foreverReplaced"
                              @updated="onDirectReplace">
            </question-replace>
        </portal>
    </tr>
</template>

<script>
import axios from 'axios'
import ToggleModal from '../../../components/popperModals/toggleModal'
import QuestionReplace from './questionReplace'
import Media from '../../../components/media/media'

export default {
  name: 'GameQuestion',
  components: { Media, QuestionReplace, ToggleModal },
  props: ['question', 'isParty', 'game'],
  data () {
    return {
      on_loading: false,
      foreverReplaced: false,
      toggleModalOptions: [
        { selectedState: false,
          states: {
            'selected': 'Случайный',
            'excluded': 'Строгий',
          },
          fontAweIcon: ['arrows-alt-h'],
          message: 'Тип замены' },
        { selectedState: false,
          states: {
            'selected': 'Навсегда',
            'excluded': 'Обычный',
          },
          fontAweIcon: ['arrows-alt-h'],
          message: 'Тип вопроса' },
      ],
    }
  },
  methods: {
    replace (id, state) {
      console.log(state, 'here')
      if (state[0] === 'Случайный') {
        this.on_loading = true
        axios.get(`/api/game-questions/${id}/replace?forever=${state[1] === 'Навсегда'}`).then((data) => {
          this.on_loading = false
          this.$emit('replaced')
        }).catch((data) => {
          this.on_loading = false
          this.$emit('failed')
        })
      } else {
        this.foreverReplaced = state[1] === 'Навсегда';
        this.modalHolder.open(`direct-replace-question-${id}`, 'Замена вопроса')
      }
    },
    onDirectReplace () {
      this.on_loading = false
      this.foreverReplaced = false
      this.$emit('replaced')
    },
  },
  computed: {
    tour () {
      return this.$route.params.tour
    },
  },
}

</script>

<style scoped>
    @tailwind utilities;

    td {
        @apply text-left
        p-2 bg-white font-sans text-sm border-t border-r border-grey-light;
    }

    .spinner {
        -webkit-animation:spin 4s linear infinite;
        -moz-animation:spin 4s linear infinite;
        animation:spin 4s linear infinite;
    }
    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
</style>
