<template>
  <div class="inline-block w-1/3 px-2" :key="local_key">
    <span
      v-if="loading"
      :disabled="disabled"
      class="
        inline-block
        text-white
        bg-brand
        px-6
        py-1
        rounded
        text-xl
        border-2 border-brand
      "
    >
      <font-awesome-icon
        :icon="['far', 'spinner-third']"
        spin
      ></font-awesome-icon>
    </span>

    <div v-if="!loading && value" class="flex" style="width: 4.1875rem">
      <button
        type="button"
        @click="showModal = true"
        class="
          btnZSet
          text-white
          bg-brand
          px-6
          py-1
          rounded
          text-xl
          border-2 border-brand
          flex-1
        "
      >
        <font-awesome-icon :icon="['fas', 'eye']"></font-awesome-icon>
      </button>
      <media-show
        v-if="showModal"
        @close="showModal = false"
        :media="value"
        :show="showModal"
      >
      </media-show>

      <button
        type="button"
        @click="mediaDelete"
        v-if="dis === false"
        class="circle text-center bg-brand-red text-white flex-1 ml-2 mt-2 px-2"
      >
        <font-awesome-icon :icon="['far', 'times']"></font-awesome-icon>
      </button>
      <p
        v-if="dis === true"
        class="text-center flex-1 ml-2 mt-2 px-2 cursor-default"
      ></p>
    </div>
    <button
      v-if="!loading && !value"
      type="button"
      :disabled="disabled"
      @click="select()"
      class="
        text-brand
        bg-white
        px-6
        py-1
        rounded
        text-xl
        border-2 border-brand
        mr-2
      "
    >
      <font-awesome-icon :icon="['far', 'plus']"></font-awesome-icon>
      <input
        type="file"
        @change="upload"
        class="hidden media_load"
        ref="file"
      />
    </button>
  </div>
</template>

<script>
import axios from 'axios'
import MediaShow from './media-show'

export default {
  name: 'AddMedia',
  components: { MediaShow },
  props: [
    'item',
    'baseUrl',
    'value',
    'dis',
    's_type',
    'collection_name',
    'supply_id',
    'type',
  ],

  data () {
    return {
      showModal: false,
      loading: false,
      localValue: '',
      local_key: 0,
    }
  },

  watch: {
    // localValue() {
    //   this.local_key += 1;
    // },
    // value() {
    //   if (this.collection_name) {
    //     if (this.collection_name === "question") {
    //       this.localValue = this.value;
    //     }
    //     if (this.collection_name !== "question") {
    //       for (let [key, value] of Object.entries(this.value)) {
    //         if (this.collection_name === key) {
    //           this.localValue = value;
    //         }
    //       }
    //     }
    //   } else {
    //     this.localValue = this.value;
    //   }
    // }
  },

  created () {
    // if (this.collection_name) {
    //   if (this.collection_name === "question") {
    //     this.localValue = this.value;
    //   }
    //   if (this.collection_name !== "question") {
    //     for (let [key, value] of Object.entries(this.value)) {
    //       if (this.collection_name === key) {
    //         this.localValue = value;
    //       }
    //     }
    //   }
    // } else {
    //   this.localValue = this.value;
    // }
  },

  methods: {
    upload () {
      this.loading = true

      let data = new FormData()
      if (this.collection_name) {
        data.append('item', this.item)
        data.append('collection_name', this.collection_name)
      } else {
        data.append('item', this.item)
      }
      data.append('info', this.$refs.file.files[0])

      axios
        .post(`${this.baseUrl}/media`, data)
        .then(({ data }) => {
          this.$emit('input', data.link)
        })
        .catch((error) => {
          alert(error.response.data.errors.info[0])
        })
        .then(() => (this.loading = false))
    },

    mediaDelete () {
      if (this.collection_name) {
        axios
          .delete(`${this.baseUrl}/media/${this.collection_name}`)
          .then(() => this.$emit('input', null))
      } else {
        axios
          .delete(`${this.baseUrl}/media/${this.item}`)
          .then(() => this.$emit('input', null))
      }
    },

    select () {
      this.$refs.file.click()
    },
  },
  computed: {
    disabled () {
      return this.dis
    },
  },
}
</script>
<style scoped>
.btnZSet {
  z-index: 100;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100%;
  width: 23px;
  height: 23px;
  font-size: 17px;
}
</style>
