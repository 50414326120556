<template>
    <on-click-outside :do="close">
        <div class="relative" :class="{ 'is-active': isOpen }">
            <font-awesome-icon ref="button" @click.prevent="switchPopper"
                               class="w-6 mx-auto my-auto text-black text-base" :icon="['fas', 'arrows-alt-h']"></font-awesome-icon>
            <div ref="panel" v-show="isOpen" class="z-50 mt-6 ml-4 absolute">
                <div class="modal-container p-4 text-sm w-full flex flex-col mx-auto justify-center items-center text-center">
                  <div v-for="(toggle, index ) in options" :key="index">
                    <p class="my-auto w-full">{{toggle.message}}</p>
                    <div class="flex py-3 w-full mx-auto">
                        <input class="mr-1" type="checkbox" v-model="toggle.selectedState">
                        <div class="ml-auto">
                            <p v-text="toggle.selectedState ? toggle.states.selected : toggle.states.excluded"></p>
                        </div>
                    </div>
                  </div>
                    <a href="" @click.prevent="confirmAction" class="my-auto p-1 bg-brand-red rounded text-sm text-white w-full mb-1 no-underline">
                        Подтвердить
                    </a>
                </div>
            </div>
        </div>
    </on-click-outside>
</template>

<script>
import Popper from 'popper.js'
import OnClickOutside from '../OnClickOutside'

export default {
  name: 'ToggleModal',
  components: {
    OnClickOutside,
  },
  props: ['options'],
  data () {
    return {
      ext: '',
      isOpen: false,
      state: [],
    }
  },
  beforeDestroy () {
    if (this.popper !== undefined) {
      this.popper.destroy()
    }
  },
  methods: {
    switchPopper () {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$nextTick(() => {
          this.setupPopper()
        })
      }
    },
    close () {
      if (!this.isOpen) {
        return
      }
      this.isOpen = false
    },
    setupPopper () {
      if (this.popper === undefined) {
        this.popper = new Popper(this.$refs.button, this.$refs.panel, {
          placement: 'bottom',
          positionFixed: true,
        })
      } else {
        this.popper.scheduleUpdate()
      }
    },
    confirmAction () {
      let states = []
      this.options.forEach((option) => {
        states.push(option.selectedState ? option.states.selected : option.states.excluded)
      })
      this.$emit('updated', states)
      this.close()
    },
  },
}
</script>

<style scoped>

    .modal-container {
        background-color: #fff;
        border-radius: 10px;
        -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
        font-family: Helvetica, Arial, sans-serif;
    }

    input[type="checkbox"] {
        width: 40px;
        height: 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: #544e68;
        outline: none;
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
        transition: 0.5s;
        position: relative;
    }

    input:checked[type="checkbox"] {
        background: #e17372;
    }

    input[type="checkbox"]::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: 0;
        left: 0;
        background: #fff;
        transform: scale(1.1);
        box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
        transition: 0.5s;
    }

    input:checked[type="checkbox"]::before {
        left: 20px;
    }
</style>
