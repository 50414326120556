<template>
    <div class="flex w-24">
        <span v-if="loading"
              class="my-auto inline-block text-white bg-brand px-6 py-1 rounded text-xl border-2 border-brand">
            <font-awesome-icon :icon="['far', 'spinner-third']" spin></font-awesome-icon>
        </span>
        <div v-if="!loading && media" class="flex relative">
            <a v-if="['txt','rtf','xlsx','ods','doc','docx','xls','xlsm','pptx'].includes(ext)"
               class="outline-none text-white no-underline"
               :href="media">
            <button class="text-white bg-brand px-6 py-1 rounded text-xl border-2 border-brand flex-1"
                    type="button">
               <font-awesome-icon :icon="['fas', 'eye']"></font-awesome-icon>
            </button>
            </a>
            <button  v-else class="text-white bg-brand px-6 py-1 rounded text-xl border-2 border-brand flex-1"
                    type="button" @click="showModal = true">
                <font-awesome-icon :icon="['fas', 'eye']"></font-awesome-icon>
            </button>

            <show-media v-if="showModal" @close="showModal = false" :media="media" :show="showModal"></show-media>
            <button class="circle text-center bg-brand-red text-white flex-1 ml-2 my-auto px-2"
                    type="button" @click="mediaDelete">
                <font-awesome-icon :icon="['far', 'times']"></font-awesome-icon>
            </button>
            <p class="text-center flex-1 ml-2 mt-2 px-2 cursor-default"></p>
        </div>
        <button v-if="!loading && !media" type="button" @click="select()"
                class="my-auto text-brand bg-white px-6 py-1 rounded text-xl border-2 border-brand mr-2">
            <font-awesome-icon :icon="['far', 'plus']"></font-awesome-icon>
            <input type="file" @change="upload" class="hidden media_load" ref="file"/>
        </button>
    </div>
</template>
<script>
import ShowMedia from './showMedia'
import axios from 'axios'

export default {
  name: 'Media',
  props: ['collection_name', 'media', 'model_id', 'model_name'],
  components: { ShowMedia },
  data () {
    return {
      showModal: false,
      loading: false,
      ext: '',
    }
  },
  methods: {
    upload () {
      this.loading = true
      let data = new FormData()
      data.append('collection_name', this.collection_name)
      data.append('item', this.collection_name)
      data.append('info', this.$refs.file.files[0])
      axios.post(`/api/${this.model_name}/${this.model_id}/media`, data)
        .then(({ data }) => {
          this.$emit('input', data.link)
        })
        .catch((error) => {
          let err = error.data.errors.info
          alert(err[0])
        })
        .then(() => {
          this.loading = false
        })
    },
    mediaDelete () {
      axios.delete(`/api/${this.model_name}/${this.model_id}/media/${this.collection_name}`).then(() => this.$emit('input', null))
    },
    select () {
      this.$refs.file.click()
    },
  },
  created () {
    setTimeout(() => {
      this.ext = this.media.split('.').pop()
    }, 350)
  },
}
</script>
<style scoped>
    @tailwind utilities;

    .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        font-size: 20px;
    }
</style>
