<template>
    <transition name="modal">
        <div class="modal-mask" >
            <div class="modal-wrapper" @click.self="$emit('close')">
                <div class="modal-container">
                    <div class="modal-body">
                        <slot name="body">
                            <video v-if="ext==='mp4'" class="w-full" controls autoplay>
                                <source :src="media">
                            </video>
                            <audio controls v-if="ext==='mp3'">
                                <source :src="media" type="audio/mpeg">
                                Your browser does not support the audio element.
                            </audio>
                            <img v-if="ext==='jpg' || ext==='jpeg' || ext==='png' || ext==='gif'" :src="media"/>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
  name: 'ShowMedia',

  props: ['media', 'show'],

  data () {
    return {
      ext: '',
    }
  },
  created () {
    const escapeHandler = (e) => {
      if (e.key === 'Escape' && this.show) {
        this.$emit('close')
      }
    }
    document.addEventListener('keydown', escapeHandler)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escapeHandler)
    })
    this.ext = this.media.split('.').pop()
  },
}

</script>

<style scoped>

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-wrapper:focus {
        outline:0;
    }

    .modal-container {
        width: 750px;
        height: auto;
        margin: 20px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-default-button {
        float: right;
    }

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

</style>
