<template>
    <div class="w-full" v-if="loaded">
        <div class="flex">
            <div class="flex-col w-2/5 pr-2">
                <div class="flex-1 pt-2">
                    <label class="text-brand-dark font-semibold">Источник</label>
                    <div class="pt-1 pb-3">
                        <input v-model="question.source" :readonly="disabled" class="border-2 flex w-full p-2"
                               placeholder="Текст" type="text"/>
                    </div>
                </div>
                <div class="flex-1 pt-2">
                    <label class="text-brand-dark font-semibold">Зачет</label>
                    <div class="pt-1">
                        <input v-model="question.credit" :readonly="disabled" class="border-2 flex w-full p-2"
                               placeholder="Текст" type="text"/>
                    </div>
                </div>
                <div class="flex-1 pt-3">
                    <label class="text-brand-dark font-semibold">Комментарий ведущему на вопрос</label>
                    <div class="pt-1">
                        <input v-model="question.comment_leading_question" :readonly="disabled"
                               class="border-2 flex w-full p-2" type="text"/>
                    </div>
                </div>
              <div class="flex-1 pt-3">
                    <label class="text-brand-dark font-semibold">Комментарий эксперта</label>
                    <div class="pt-1 pb-3">
                        <input v-model="question.expert_comment" :readonly="disabled"
                               class="border-2 flex w-full p-2" type="text"/>
                    </div>
                </div>
            </div>
            <div class="flex-col w-3/5 pl-2">
                <div class="flex-1 pt-2">
                    <label class="text-brand-dark font-semibold">Комментирии редактора</label>
                    <div class="pt-1 pb-3">
                        <div class=" flex border-2 bg-white">
                            <input class=" flex w-full p-2 mr-3"
                                   :placeholder="question.question_revision[0] ? question.question_revision[0].reason : ''"
                                   type="text" readonly/>
                            <button type="button" @click="showReasons = true">
                                <font-awesome-icon class="text-brand-light text-2xl mr-2"
                                                   :icon="['far', 'comment-alt']">
                                </font-awesome-icon>
                            </button>

                            <show-reasons :question_revision="question.question_revision" v-if="showReasons"
                                          @close="showReasons = false">
                            </show-reasons>

                        </div>
                    </div>
                </div>

                <div class="flex-1 pt-2">
                    <label class="text-brand-dark font-semibold">Незачёт</label>
                    <div class="pt-1 pb-3">
                        <input v-model="question.non_reg" :readonly="disabled" class="border-2 flex w-full p-2"
                               placeholder="Текст" type="text"/>
                    </div>
                </div>
                <div class="flex-1">
                    <label class="text-brand-dark font-semibold">Комментарий ведущему на ответ</label>
                    <div class="pt-1">
                        <input v-model="question.comment_leading_answer" :readonly="disabled"
                               class="border-2 flex w-full p-2" placeholder="Текст" type="text"/>
                    </div>
                </div>
            </div>
        </div>
        <tags :question="question" v-model="question.tags" :dis="dis"
              :track="question.track" @track-updated="(newVal) => question.track = newVal"></tags>
    </div>
</template>
<script>

import ShowReasons from './show-reasons'
import Tags from './tags'

export default {
  name: 'AdditionalFeatures',
  components: { ShowReasons, Tags },
  props: ['questions', 'dis'],

  data () {
    return {
      showReasons: false,
      reason: '',
      loaded: false,
      question: {},
    }
  },
  created () {
    this.loaded = true
    this.question = this.questions
  },
  computed: {
    disabled () {
      return this.dis
    },
    getQuestion () {
      this.question = this.questions
    },
  },
}
</script>
