<template>
  <div class="form">
    <div class="w-full px-4 mb-4 pt-4 font-semibold text-brand -mx-2">
      <div class="flex">
        <div class="mx-2">Номер игры:</div>
        <div class="mx-2">
          {{id}}
        </div>
      </div>
      <div class="flex mt-2 mb-4">
        <div class="mx-2">Ссылка:</div>
        <div class="mx-2">
          {{guid}}
        </div>
      </div>
      <div v-if="party" class="flex -mt-2 mb-4">
        <div class="mx-2">Номер мозгопати:</div>
        <div class="mx-2">
          {{party.id}}
        </div>
      </div>
    </div>
    <form v-if="party">
      <div class="w-full px-4 mb-4 flex">
        <div class="pt-8 flex justify-center mb-auto">
          <div class="no-underline circle bg-brand text-white">{{id}}</div>
        </div>
        <div class="w-full flex flex-row justify-between items-start">
          <div class="flex flex-col w-1/3  mr-5">
            <div class="flex-1 mx-2 flex flex-col">
              <label class="font-semibold text-brand pb-1">Шаблон игры</label>
              <div class="w-full mt-auto">
                <div v-text="templateName" class="w-full p-2 border border-brand-light rounded"></div>
              </div>
            </div>
            <div class="flex-1 mx-2 flex flex-col my-3">
              <label class="font-semibold text-brand pb-1">Название игры</label>
              <div class="w-full mt-auto">
                <input
                  type="text"
                  v-model="form.name"
                  class="w-full p-2 border border-brand-light rounded"
                  placeholder="Введите название"
                >
              </div>
            </div>

            <div class="flex justify-start items-center ml-2">
              <input
                  type="checkbox"
                  v-model="form.show_on_main_page"
                  class="mr-4 border border-brand-light rounded"
                  placeholder="Введите название"
                >
              <label class="font-semibold text-brand pb-1">Показывать на главной</label>
            </div>

            <div class="flex justify-start items-center ml-2">
              <input
                  type="checkbox"
                  v-model="form.is_display_only_in_promo"
                  class="mr-4 border border-brand-light rounded"
                  placeholder="Введите название"
                >
              <label class="font-semibold text-brand pb-1">Показывать только на промо-сайте</label>
            </div>
           <div class="mt-3">
             <label class="font-semibold text-brand pb-1">Позиция в карусели рейтинга</label>
             <select v-model="form.popular_rank" class="p-1 mt-1 border-solid border-brand-light border w-full rounded">
               <option value="" class="bg-brand rounded text-white hover:bg-brand-dark">Не указано</option>
               <option v-for="digit in [1,2,3,4]" :key="digit" :value="digit" class="bg-brand rounded text-white hover:bg-brand-dark">{{digit}}</option>
            </select>
           </div>
           <div class="mt-3">
             <label class="font-semibold text-brand pb-1">Возрастной ценз</label>
             <input type="number" v-model="form.age_rating" class="w-full p-2 mt-1 border border-brand-light rounded"
                     placeholder="Введите возрастной ценз">
           </div>
            <div
                 class="flex-auto pt-1 flex flex-col">
              <label class="font-semibold text-brand pb-1">Должно быть опубликовано</label>
              <div class="w-full mt-auto">
                <v-date-picker
                        mode='single'
                        :value="should_be_published_at"
                        @input="setShouldBePublishedAt($event)"
                        class="flex-auto py-1.5 px-3 text-grey-darker border-solid border-brand-light border rounded shadow appearance-none"
                        show-caps>
                </v-date-picker>
              </div>
            </div>
            <div class="mt-3">
              <label class="font-semibold text-brand pb-1">Продолжительность</label>
              <input type="text" v-model="form.duration" class="w-full p-2 mt-1 border border-brand-light rounded"
                     placeholder="Введите продолжительность">
            </div>
            <div class="mt-3">
              <label class="font-semibold text-brand pb-1">Идентификатор TWIN</label>
              <input type="text" v-model="form.twin_uuid" class="w-full p-2 mt-1 border border-brand-light rounded"
                     placeholder="uuid">
            </div>
            <div class="mt-3">
              <label class="font-semibold text-brand pb-1">Система</label>
              <select v-model="form.system" class="p-1 mt-1 border-solid border-brand-light border w-full rounded">
                <option value="" class="bg-brand rounded text-white hover:bg-brand-dark">Не указано</option>
                <option value="MOZGO" class="bg-brand rounded text-white hover:bg-brand-dark">MozgoParty</option>
                <option value="PARTYSTORE" class="bg-brand rounded text-white hover:bg-brand-dark">PartyStore</option>
              </select>
            </div>
          </div>
          <div class="flex w-2/5 mr-5 h-full">
            <div class="flex-1 mx-2 flex flex-col">
              <label class="font-semibold text-brand pb-1">Описание игры</label>
              <div class="w-full h-full mt-auto self-stretch">
                <textarea
                  v-model="form.description"
                  class="w-full p-2 h-full outline-none border border-brand-light rounded-lg"
                ></textarea>
              </div>
              <label class="font-semibold text-brand mt-2 pb-1">Описание для мегафона</label>
              <div class="w-full h-auto mt-auto self-stretch">
                <textarea
                    v-model="form.megaphone_description"
                    class="w-full p-2 h-24 outline-none border border-brand-light rounded-lg"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="flex flex-col w-1/4">
            <div class="flex justify-start">
              <div class="flex-auto mx-2 flex flex-col">
                <label class="font-semibold text-brand pb-1">Афиша</label>
                <div class="w-full mt-auto">
                  <media
                    :model_name="'games'"
                    :model_id="id"
                    :media="media.bigCoverMedia ? media.bigCoverMedia : ''"
                    :collection_name="'bigCoverMedia'"
                    @input="fetchData"
                  ></media>
                </div>
              </div>
              <div class="flex-auto mx-2 flex flex-col">
                <label class="font-semibold text-brand pb-1">Аватар</label>
                <div class="w-full mt-auto">
                  <media
                    :model_name="'games'"
                    :model_id="id"
                    :media="media.smallCoverMedia ? media.smallCoverMedia : ''"
                    :collection_name="'smallCoverMedia'"
                    @input="fetchData"
                  ></media>
                </div>
              </div>
            </div>

            <div class="flex justify-start">
              <div class="flex-auto mx-2 flex flex-col">
                <div class="w-full mt-4">
                  <label class="font-semibold text-brand pb-1">Обложка</label>
                  <media
                    :model_name="'games'"
                    :model_id="id"
                    :media="media.poster ? media.poster : ''"
                    :collection_name="'poster'"
                    @input="fetchData"
                  ></media>
                </div>
              </div>
              <div class="flex-auto mx-2 flex flex-col">
                <div class="w-full mt-4">
                  <label class="font-semibold text-brand pb-1">Аватар</label>
                  <media
                    :model_name="'games'"
                    :model_id="id"
                    :media="media.avatar ? media.avatar : ''"
                    :collection_name="'avatar'"
                    @input="fetchData"
                  ></media>
                </div>
              </div>
            </div>
            <div class="flex justify-start my-3">
              <button
                class="outline-none w-24 mx-2 mt-auto text-white bg-brand px-4 py-2 text-sm rounded border-2 border-brand"
                type="button"
                @click.prevent="openExperts"
              >Выгрузка
                <font-awesome-icon v-if="loadingExperts"  class="absolute spinner" :icon="['fas', 'spinner']"></font-awesome-icon>
              </button>

              <a target="_blank" :href="checkLink" v-show="partyHash">
                <button
                  class="outline-none w-24 mx-2 mt-auto text-white bg-brand px-4 py-2 text-sm rounded border-2 border-brand"
                  type="button"
                >Проверка</button>
              </a>
            </div>
            <MultiPrice
            v-model="form.price[currency]"
            v-for="currency in currencyTitles"
            :value="party.price[currency]"
            :currency="currency"
            :key="currency"  />
            <PartyCategoriesSelector
                class="flex-1 flex flex-col justify-start mx-2"
                :categories="categories" :layer="0"
                v-model="form.category_id"
            />
              <div class="mt-3 mx-2">
                  <label class="font-semibold text-brand pb-1">Сложность игры</label>
                  <select v-model="form.complexity" class="p-1 mt-1 border-solid border-brand-light border w-full rounded">
                      <option value="" class="bg-brand rounded text-white hover:bg-brand-dark">Не указано</option>
                      <option v-for="digit in [1,2,3,4,5]" :key="digit" :value="digit" class="bg-brand rounded text-white hover:bg-brand-dark">{{digit}} из 5</option>
                  </select>
              </div>
            <div class="mt-3 mx-2">
              <label class="font-semibold text-brand pb-1">Стоимость производства</label>
              <input type="number" v-model="form.expenses" class="w-full p-2 mt-1 border border-brand-light rounded"
                     placeholder="Введите возрастной ценз">
            </div>
          </div>
        </div>
      </div>
    </form>
    <form v-else>
      <div class="w-full px-4 mb-4 flex">
        <div class="pt-8 flex justify-center mb-auto">
          <div class="no-underline circle bg-brand text-white">{{id}}</div>
        </div>
        <div class="flex w-2/5 mr-5 h-32">
          <div class="flex-1 mx-2 flex flex-col">
            <label class="font-semibold text-brand pb-1">Описание игры</label>
            <div class="w-full h-full mt-auto self-stretch">
              <textarea
                v-model="form.description"
                class="w-full p-2 h-full outline-none border border-brand-light rounded-lg"
              ></textarea>
            </div>
          </div>
        </div>
        <div  v-if="!on_loading" class="flex flex-col mr-2 ml-6">
          <div class="flex items-baseline ">
            <label class="font-semibold text-brand mr-2 mt-8">Исключить туры</label>
            <div class="">
              <input
                  type="text"
                  v-model="excludedTours"
                  class="p-2 border border-brand-light rounded"
                  placeholder="0-8"
                  @keypress="denySymbols"
              >
            </div>
            <div>
              <button @click="repeatGeneration"
                      class="outline-none ml-6 text-white bg-brand px-4 py-2 text-sm rounded border-2 border-brand"
                      type="button"
              >Повторить генерацию</button>
            </div>
          </div>
          <button
              class="outline-none w-48 mt-auto text-white bg-brand px-4 py-2 text-sm rounded border-2 border-brand"
              type="button"
              @click.prevent="openExperts"
          >Выгрузка эксперта
            <font-awesome-icon v-if="loadingExperts"  class="absolute spinner" :icon="['fas', 'spinner']"></font-awesome-icon>
          </button>
        </div>
        <div v-else class="-mt-10">
                <Spinner/>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Form from 'form-backend-validation'
import debounce from 'lodash/debounce'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import axios from 'axios'
import Media from '../../../components/media/media'
import swal from 'sweetalert2'
import Spinner from '@/components/spinner.vue'
import MultiPrice from './MultiPrice'
import PartyCategoriesSelector from './partyCategoriesSelector.vue'
import FileDownload from "js-file-download";
export default {
  name: 'GameForm',
  props: ['partyHash'],
  components: { Media, Spinner, MultiPrice, PartyCategoriesSelector },
  data () {
    return {
      form: {
        price: '',
        name: '',
        description: '',
        megaphone_description: '',
        show_on_main_page: false,
        is_display_only_in_promo: false,
        popular_rank: '',
        age_rating: '',
        expenses: '',
        should_be_published_at: new Date(),
        duration: '',
        twin_uuid: '',
        system: '',
        category_id: '',
        complexity: '',
      },
      party: '',
      templateName: '',
      media: {
        bigCoverMedia: '',
        smallCoverMedia: '',
        poster: '',
        avatar: '',
      },
      on_loading: false,
      excludedTours: '',
      guid: '',
      should_be_published_at: new Date(),
      loadingExperts: false,
      categories: []
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    checkLink () {
      if (this.partyHash) {
        const url = window.APP.API_MP_GAME || process.env.VUE_APP_API_MP_GAME
        return `${url}${this.partyHash}`
      }
    },
    currencyTitles () {
      return Object.keys(this.party.price)
    },
  },
  created () {
    this.fetchData();
    this.fetchCategories()
  },
  watch: {
    form: {
      handler (val, oldval) {
        if (oldval) {
          console.log('ggg', val)
          this.save(val)
        }
      },
      deep: true,
    },
  },
  methods: {
    save: debounce(function (form) {
      axios.patch(`/api/games/${this.id}`, form.data()).then(() => {})
    }, 1000),
    fetchData () {
      axios.get(`/api/games/show/${this.id}`).then(({ data }) => {
        this.party = data.party
        this.guid = data.guid
        if (this.party) {
          this.should_be_published_at = data.should_be_published_at ? parse(data.should_be_published_at, 'dd.MM.yyyy', new Date()) : new Date()
          this.form = new Form({
            name: this.party ? this.party.name : '',
            price: this.party ? this.party.price : '',
            show_on_main_page: this.party ? this.party.show_on_main_page : '',
            is_display_only_in_promo: this.party ? this.party.is_display_only_in_promo : '',
            description: data.description ? data.description : '',
            megaphone_description: this.party ? this.party.megaphone_description : '',
            popular_rank: this.party ? this.party.popular_rank : '',
            age_rating: this.party ? this.party.age_rating : '',
            expenses: this.party ? this.party.expenses : '',
            duration: this.party ? this.party.duration : '',
            twin_uuid: this.party ? this.party.twin_uuid : '',
            system: this.party ? this.party.system : '',
            category_id: this.party ? this.party.category_id: '',
            complexity: this.party ? this.party.complexity: '',
            should_be_published_at: format(this.should_be_published_at, 'yyyy-MM-dd'),
          }, { http: axios })
          this.media = data.media
          this.templateName = data.template ? data.template.name : ''
          this.$emit('partyDetected', true)
        } else {
          this.form = new Form({
            description: data.description ? data.description : '',
          }, { http: axios })
          this.$emit('partyDetected', false)
        }
        console.log('vvvv', this.form)
        this.$emit('templateLoaded', data.template ? data.template : '')
      })
    },
    openExperts() {
      this.loadingExperts = true;
      const url = window.APP.API_BASE_URL || process.env.VUE_APP_API_BASE_URL
      this.downloadExcel(`${url}/game/${this.id}/excels/experts`, 'Для эксперта');
    },
    downloadExcel(link, name) {
      axios.get(link, { responseType: 'blob' })
          .then((response) => {
            this.loadingExperts = false;
            FileDownload(response.data, `${name}.xlsx`);
          })
          .catch(() => {
            this.loadingExperts = false;
          });
    },
    repeatGeneration () {
      this.on_loading = true
      axios.get(`api/game/generate/continue?game_id=${this.id}&excluded_tours=${this.excludedTours}`).then(({ data }) => {
        this.on_loading = false
        swal('Успех!', 'Игра успешно сгенерирована', 'success')
      }).catch((error) => {
        if (error.response.status === 500) { this.error_message = error.response.data; swal('Ошибка!', this.error_message, 'error') }
        if (error.response.status === 422) { this.error_message = error.response.data.errors.tours[0]; swal('Ошибка!', this.error_message, 'error') }
        this.on_loading = false
      })
    },

    denySymbols () {
      let evt = window.event
      let regex = new RegExp('^[0-8,-]+$')
      let key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode)
      if (!regex.test(key)) {
        evt.preventDefault()
        return false
      }
    },
    setShouldBePublishedAt (value) {
      this.should_be_published_at = value
      this.$set(this.form, 'should_be_published_at', format(value, 'yyyy-MM-dd'))
    },
    fetchCategories () {
      axios.get(`/api/mozgoparty-games/categories/count`).then(({ data }) => {
        this.categories = data
      })
    }
  },
}
</script>

<style scoped>
@tailwind utilities;

th {
  @apply text-left
        p-2 bg-white font-sans text-sm border-b border-r border-grey-light;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  font-size: 16px;
}

.spinner {
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

</style>
