<template>
  <div class="flex flex-col">
    <p class="pr-4 pb-1 font-semibold text-brand-dark">{{cell.name}}</p>
    <media
        model_name="templates"
        :model_id="template_id"
        :media="tourMedia ? tourMedia[cell.collection_name] : ''"
        :collection_name="cell.collection_name"
        @input="$emit('cellUpdated', cell)"
        class="pr-4 mr-12"
    ></media>
  </div>
</template>
<script>
import Media from '../../../components/media/media'

const uuidv4 = require('uuid/v4')

export default {
  name: 'AnimationCell',
  components: { Media},
  props: ['item', 'tourMedia', 'template_id'],
  data () {
    return {
      'cell': {
        'name': '',
        'collection_name': '',
      },
    }
  },
  created () {
    this.cell = this.item
  },
}
</script>
<style scoped>
    @tailwind utilities;
</style>
