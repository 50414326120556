<template>
    <div class="container">
        <div class="round">
            <input :checked="value" type="checkbox" :id="id" />
            <label @click="selected"></label>
        </div>
    </div>
</template>

<script>
export default {
  name: 'NoMatterCheckBox',
  props: ['id', 'value'],
  methods: {
    selected () {
      this.$emit('input', !this.value)
    },
  },
}
</script>

<style scoped>
    body {
        background-color: #f1f2f3;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .container {
        margin: 0 auto;
    }

    .round {
        position: relative;
    }

    .round label {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 28px;
        left: 0;
        position: absolute;
        top: 0;
        width: 28px;
    }

    .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 7px;
        opacity: 0;
        position: absolute;
        top: 8px;
        transform: rotate(-45deg);
        width: 12px;
    }

    .round input[type="checkbox"] {
        visibility: hidden;
    }

    .round input[type="checkbox"]:checked + label {
        background-color: #66bb6a;
        border-color: #66bb6a;
    }

    .round input[type="checkbox"]:checked + label:after {
        opacity: 1;
    }
</style>
