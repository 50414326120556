<template>
  <div class="container mx-auto flex flex-col px-8 mx-4 h-full">
    <div class="container px-4 mx-auto flex justify-between h-1/2">
      <div class="rounded border-grey shadow-md text-left w-full h-full overflow-x-auto"
           :class="{ 'mb-44':  (useBottomMarginValue === true && !showAnimations)}">
        <form>
          <div class="w-full px-4 mb-4">
            <div class="flex">
              <div class="pt-12 pr-2 flex justify-center mb-auto">
                <div class="no-underline circle bg-brand text-white">{{ id }}</div>
              </div>
              <div class="pt-6 flex w-full items-center">
                <div class="flex flex-col">
                  <div class="flex">
                    <div class="flex flex-col mx-2 mb-auto">
                      <label class="font-semibold text-brand pb-1">Название игры</label>
                      <div class="w-full mb-auto">
                        <input
                            type="text"
                            v-model="form.name"
                            class="w-full p-2 border border-brand-light rounded"
                            placeholder="Введите название"
                        >
                      </div>
                    </div>
                    <div class=" flex flex-col mx-2 mb-auto">
                      <label class="font-semibold text-brand pb-1">Туры</label>
                      <div class="w-full mb-auto">
                        <input
                            :disabled="!this.form.game_type_id"
                            :class="[ this.form.game_type_id ? '' : 'border-brand-red border-2']"
                            type="text"
                            v-model="form.tour_count"
                            max="10"
                            class="w-full p-2 border border-brand-light rounded"
                            value="7"
                        >
                        <p
                            class="pt-1 font-semibold text-red text-sm"
                            v-if="!this.form.game_type_id"
                        >Сначала выберите категорию</p>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col mb-auto mx-2">
                    <label class="font-semibold text-brand pb-1">Ссылка на макеты</label>
                    <div class="w-full mb-auto">
                      <input
                          type="text"
                          v-model="form.partner_layouts"
                          class="w-full p-2 border border-brand-light rounded"
                          placeholder="Введите ссылку"
                      >
                    </div>
                  </div>
                </div>

                <div class="flex flex-col">
                  <div class="flex">
                    <div class="flex flex-col ml-4">
                      <p class="pl-2 pb-1 font-semibold text-brand-dark w-40">Фон для игры</p>
                      <media
                          model_name="templates"
                          :model_id="id"
                          :media="media.slide_background ? media.slide_background : ''"
                          :collection_name="'slide_background'"
                          @input="getData"
                          class="pl-2 pr-4 mr-12"
                      ></media>
                    </div>
                    <div class="flex flex-col">
                      <p class="pl-2 pb-1 font-semibold text-brand-dark w-40">Док с описанием</p>
                      <media
                          model_name="templates"
                          :model_id="id"
                          :media="media.tour_description ? media.tour_description : ''"
                          :collection_name="'tour_description'"
                          @input="getData"
                          class="pl-2 pr-4 mr-12"
                      ></media>
                    </div>
                    <div class="flex flex-col">
                      <p class="pl-2 pb-1 font-semibold text-brand-dark w-40">Обложка лайв</p>
                      <media
                          model_name="templates"
                          :model_id="id"
                          :media="media.live_cover ? media.live_cover : ''"
                          :collection_name="'live_cover'"
                          @input="getData"
                          class="pl-2 pr-4 mr-12"
                      ></media>
                    </div>
                    <div class="pl-2 flex flex-col ml-auto mt-4">
                      <label class="flex justify-start cursor-pointer text-brand-dark font-semibold">
                        Начать с 0
                        <div
                            :class="[form.start_from_zero ? 'bg-brand-dark' : '']"
                            class="brand-checkbox my-auto ml-4"
                        >
                          <input
                              class
                              :checked="form.start_from_zero"
                              @click.stop="check_for_zero"
                              type="checkbox"
                          >
                        </div>
                      </label>
                    </div>
                  </div>

                  <div class="mt-2 ml-4 flex">
                    <div class="flex flex-col">
                      <p class="pl-2 pb-1 font-semibold text-brand-dark w-64">Фон для выгрузки в соцсети</p>
                      <media
                          model_name="templates"
                          :model_id="id"
                          :media="media.social_network_export_background ? media.social_network_export_background : ''"
                          :collection_name="'social_network_export_background'"
                          @input="getData"
                          class="pl-2 pr-4 mr-12"
                      ></media>
                    </div>
                    <div class="ml-16 flex flex-col">
                      <p class="pl-2 pb-1 font-semibold text-brand-dark w-40">Доп материалы</p>
                      <media
                          model_name="templates"
                          :model_id="id"
                          :media="media.additional_materials ? media.additional_materials : ''"
                          :collection_name="'additional_materials'"
                          @input="getData"
                          class="pl-2 pr-4 mr-12"
                      ></media>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="flex-auto pt-6 flex flex-col">
                <label class="font-semibold text-brand pb-1">Описание игры</label>
                <div class="w-full mb-auto">
                                    <textarea v-model="form.description" rows="5"
                                              class="w-full p-2 border border-brand-light rounded"
                                              placeholder="Введите описание"></textarea>
                </div>
              </div>

            </div>

            <div class="flex">

              <!--               game types       -->
              <FilterGameTypes ref="gameTypes" @input="typeChanged" :defaultGameTypeChain="gameTypeChain">
              </FilterGameTypes>

              <div class="flex-auto pl-2 flex flex-col">
                <label class="font-semibold text-brand pb-2">Язык</label>
                <div class="w-full mb-auto">
                  <select v-model="form.language"
                          class="w-full px-2 py-1 border border-brand-light rounded"
                  >
                    <option value="Русский" class="bg-brand rounded text-white hover:bg-brand-dark">Русский</option>
                    <option value="Английский" class="bg-brand rounded text-white hover:bg-brand-dark">Английский
                    </option>
                    <option value="Французский" class="bg-brand rounded text-white hover:bg-brand-dark">Французский
                    </option>
                    <option value="Украинский" class="bg-brand rounded text-white hover:bg-brand-dark">Украинский
                    </option>
                    <option value="Польский" class="bg-brand rounded text-white hover:bg-brand-dark">Польский</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full" v-if="form.tour_count">
            <div class="w-full" v-if="form.rules.tours">
              <tour-switch :id="id" :tours="form.rules.tours"></tour-switch>
              <router-view
                  @added="setQuestions(...arguments)"
                  :key="questionsSettingsFormKey"
                  :start_from_zero="form.start_from_zero"
                  :gameType="gameType"
                  :subType="subType"
                  :gameTypeId="form.game_type_id"
                  :gameTopicId="form.game_topic_id"
                  :slideBackgrounds="slideBackgrounds"
                  @tour_chane="tour_change"
              ></router-view>
              <div v-if="isEditor || isAdmin">
                <p @click="showExpertPanel" class="mx-4 w-80 cursor-pointer font-semibold text-brand"
                   style="width: fit-content;">Расширенный ввод (для экспертов)
                  <font-awesome-icon v-if="expertPanel"
                                     :icon="['fal', 'angle-up']">
                  </font-awesome-icon>
                  <font-awesome-icon v-if="!expertPanel"
                                     :icon="['fal', 'angle-down']"></font-awesome-icon>
                </p>
                <v-jsoneditor v-if="expertPanel" class="p-4" v-model="form.rules"
                              :options="{mode: 'form',onChange: rerender}" :plus="false" :height="'400px'"
                              @error="onError"></v-jsoneditor>
              </div>
            </div>
            <color-picker :custom_design="form.custom_design" @useBottomMargin="useBottomMargin"
                          @disableBottomMargin="disableBottomMargin"></color-picker>
            <slide-backgrounds
                class="mt-4"
                ref="slideBackgroundsList"
                :template_id="id"
                @setBackgrounds="setBackgrounds"
            ></slide-backgrounds>
            <animations
                ref="animationList"
                :gameType="gameType"
                :tour_count="form.tour_count"
                :template_id="id"
                :start_from_zero="form.start_from_zero"
                :rules="form.rules"
                @showAnimationsVal="showAnimationsVal"
            ></animations>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import TourSwitch from './tour-switch'
import Form from 'form-backend-validation'
import debounce from 'lodash/debounce'
import axios from 'axios'
import FilterGameTypes from '../../components/FilterGameTypes.vue'
import VJsoneditor from 'v-jsoneditor/src/index'
import Animations from './animations/animations'
import Media from '../../components/media/media'
import ColorPicker from '@/pages/game_templates/color-picker'
import SlideBackgrounds from "@/pages/game_templates/slideBackgrounds/slide-backgrounds";

const uuidv4 = require('uuid/v4')

export default {
  components: {SlideBackgrounds, ColorPicker, Media, Animations, TourSwitch, VJsoneditor, FilterGameTypes},
  name: 'TemplateForm',
  data() {
    return {
      slide_background: 'Задний фон для игры',
      tour_description: 'Док с описанием',
      additional_materials: 'Доп материалы',
      live_cover: 'Обложка для лайва',
      social_network_export_background: 'Фон для выгрузки в соцсети',
      form: false,
      gameTypes: {},
      gameTypeChain: [],
      typeId: '',
      subTypeId: '',
      sharedState: {
        tours: [],
      },
      tourCount: 0,
      media: {
        slide_background: '',
        tour_description: '',
        additional_materials: '',
        live_cover: '',
        social_network_export_background: '',
      },
      gameType: '',
      subType: '',
      isChangeColor: false,
      isChangeColorInType: false,
      isChangeColorInsubType: false,
      isChangeColorInTopic: false,
      questionsSettingsFormKey: uuidv4(),
      expertPanel: false,
      useBottomMarginValue: false,
      showAnimations: false,
      slideBackgrounds: [],
    }
  },
  provide() {
    const templateTours = {}
    Object.defineProperty(templateTours, 'data', {
      enumerable: true,
      get: () => this.sharedState,
    })
    return {templateTours}
  },
  watch: {
    form: {
      handler(val, oldval) {
        if (oldval) {
          if (val.tour_count !== this.tourCount) {
            this.changeTours(val.tour_count, this.tourCount)
          }
          this.save(val)
        }
      },
      deep: true,
    },
    typeId(val, oldval) {

    },
    // subTypeId (val, oldval) {
    //   if (oldval !== '') this.form.game_topic_id = ''
    //   this.form.game_type_id = val
    //   console.dir(val)
    //   if (!val) this.subType = ''
    //   else this.subType = this.gameTypes[this.typeId].subType.data[val].name
    //   if ((!this.form.custom_design && this.subTypeId) || this.isChangeColorInsubType) {
    //     this.form.custom_design = this.gameTypes[this.typeId].subType.data[val].custom_design
    //   }
    //   this.isChangeColorInsubType = true
    // },

  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      axios.get(`/api/templates/${this.id}`).then(({data}) => {
        console.dir(data)
        this.form = new Form({
          name: data.name || '',
          description: data.description || '',
          tour_count: data.tour_count,
          game_category: data.game_category,
          game_type_id: data.game_type_id,
          game_topic_id: data.game_topic_id,
          language: data.language || 'Русский',
          partner_layouts: data.partner_layouts || '',
          rules: data.rules || {},
          custom_design: data.custom_design || {},
          start_from_zero: data.start_from_zero || false,
        }, {http: axios})
        this.tourCount = data.tour_count
        this.gameTypeChain = data.game_type_chain
        this.media = data.media
        this.sharedState.tours = this.form.rules.tours
        this.getGameTypes()
      })
    },
    getGameTypes() {
      axios.get('/partners/game-types').then(data => {
        this.gameTypes = data.data.data
      })
    },
    // getTypeIds () {
    //   if (this.form.game_type_id) {
    //     Object.values(this.gameTypes).forEach((type) => {
    //       if (type.id === this.form.game_type_id) {
    //         this.typeId = type.id
    //         this.subTypeId = ''
    //       }
    //       if (Object.keys(type.subType.data).length > 0) {
    //         Object.values(type.subType.data).forEach(subType => {
    //           if (subType.id === this.form.game_type_id) {
    //             this.typeId = subType.parent_id
    //             this.subTypeId = subType.id
    //           }
    //         })
    //       }
    //     })
    //   }
    //   this.subType = this.gameTypes[this.typeId].subType.data[this.subTypeId].name
    // },
    save: debounce(function (form) {
      axios.patch(`/api/templates/${this.id}`, form.data()).then(() => {
        if (
            this.$route.params.tour &&
            this.$route.params.tour > this.tourCount
        ) {
          this.$router.push({
            name: 'template_questions',
            params: {id: this.id, tour: this.tourCount},
          })
        }
      })
    }, 1000),
    setQuestions(params) {
      this.form.rules.tours[params.tour_number].tour_id = params.tour_id
      this.form.rules.tours[params.tour_number].mechanic_id = params.mechanic_id
      this.form.rules.tours[params.tour_number].mechanic_type = params.mechanic_type
      this.form.rules.tours[params.tour_number].need_confidence = params.need_confidence
      this.form.rules.tours[params.tour_number].is_test = params.is_test
      this.form.rules.tours[params.tour_number].questions = []
      this.form.rules.tours[params.tour_number].questions = params.questions
      this.form.rules.tours[params.tour_number].displayed_number = params.displayed_number
      this.form.rules.tours[params.tour_number].tour_name = params.tour_name
      this.sharedState.tours = this.form.rules.tours
    },
    changeTours: debounce(function (val, oldval) {
      if (!this.form.rules.tours) {
        this.$set(this.form, 'rules', {})
        this.$set(this.form.rules, 'tours', [])
      }
      if (oldval < Number(val)) {
        this.addEmptyTours(Number(val), oldval)
      } else {
        this.dropExcessTours(Number(val), oldval)
      }
      this.tourCount = val
      this.sharedState.tours = this.form.rules.tours
      this.save(this.form)
    }, 1000),
    addEmptyTours(val, oldval) {
      if (this.form.start_from_zero) {
        for (let i = oldval; i < val; i++) {
          this.form.rules.tours.push({
            id: this.form.rules.tours.length,
            questions: [],
          })
        }
      } else {
        for (let i = oldval; i < val; i++) {
          this.form.rules.tours.push({
            id: this.form.rules.tours.length + 1,
            questions: [],
          })
        }
      }
    },
    dropExcessTours(val, oldval) {
      for (let i = val; i < oldval; i++) {
        this.form.rules.tours.pop()
      }
    },
    tour_change(val) {
      let testArr = JSON.parse(JSON.stringify(this.form.rules.tours))
      let resultArr = []
      let newIdx = testArr.findIndex(item => item.id === val.new_id)
      let oldIdx = testArr.findIndex(item => item.id === val.old_id)
      if (oldIdx < newIdx && val.new_id !== 0) {
        resultArr = [
          ...testArr.slice(0, oldIdx),
          ...testArr.slice(oldIdx + 1, newIdx),
          ...[testArr[newIdx]],
          ...[testArr[oldIdx]],
          ...testArr.slice(newIdx + 1),
        ]
        resultArr.forEach((item, indx) => {
          if (indx > newIdx || indx < oldIdx) return
          if (indx === newIdx) {
            item.id = val.new_id
            return
          }
          item.id -= 1
        })
      } else if (oldIdx > newIdx && val.new_id !== 0) {
        resultArr = [
          ...testArr.slice(0, newIdx),
          ...[testArr[oldIdx]],
          ...[testArr[newIdx]],
          ...testArr.slice(newIdx + 1, oldIdx),
          ...testArr.slice(oldIdx + 1),
        ]
        resultArr.forEach((item, indx) => {
          if (indx < newIdx || indx > oldIdx) return
          if (indx === newIdx) {
            item.id = val.new_id
            return
          }
          item.id += 1
        })
      } else if (val.new_id === 0) {
        resultArr = [
          ...[testArr[oldIdx]],
          ...testArr.slice(0, oldIdx),
          ...testArr.slice(oldIdx + 1),
        ]
        resultArr.forEach((item, idx) => (item.id = idx))
      }

      this.form.rules.tours = resultArr
      console.log(this.form.rules.tours)
      // console.log(testArr, newIdx, oldIdx, val);
    },
    check_for_zero() {
      this.form.start_from_zero = !this.form.start_from_zero
      if (this.form.start_from_zero) {
        this.tour_begin_at_zero()
      } else {
        this.tour_begin_at_one()
      }
    },
    tour_begin_at_zero() {
      let testArr = JSON.parse(JSON.stringify(this.form.rules.tours))
      testArr.forEach((item, indx) => {
        item.id = indx
      })

      this.form.rules.tours = testArr
    },
    tour_begin_at_one() {
      let testArr = JSON.parse(JSON.stringify(this.form.rules.tours))
      testArr.forEach((item, indx) => {
        item.id = indx + 1
      })

      this.form.rules.tours = testArr
    },
    typeChanged(e) {
      this.getGameName(e)
      this.form.game_type_id = e.query
      let lastCategory = e.lastCategory
      let gameChain = e.gameTypeChain
      if ((!this.form.custom_design)) {
        this.form.custom_design = lastCategory[gameChain[gameChain.length - 1]].custom_design
      }
      this.isChangeColorInType = true
    },
    getGameName(e) {
      let lastCategory = e.lastCategory
      let gameTypeChain = e.gameTypeChain
      this.gameType = lastCategory[gameTypeChain[gameTypeChain.length - 1]].name

      this.form.game_category = 'classic'
      e.gameTypeChain.forEach((key) => {
        if (key === 'product_6') {
          this.form.game_category === 'party'
        }
      })
    },
    // // get gameType
    // findProp (obj, key, out , id) {
    //   var i,
    //     proto = Object.prototype,
    //     ts = proto.toString,
    //     hasOwn = proto.hasOwnProperty.bind(obj)
    //
    //   if (ts.call(out) !== '[object Array]') out = []
    //
    //   for (i in obj) {
    //     if (hasOwn(i)) {
    //       if (i === key) {
    //         out.push(obj[i])
    //       } else if (ts.call(obj[i]) === '[object Array]' || ts.call(obj[i]) === '[object Object]') {
    //         this.findProp(obj[i], key, out)
    //       }
    //     }
    //   }
    //
    //   return out
    // },
    showExpertPanel() {
      this.expertPanel = !this.expertPanel
    },
    onError() {
      console.log('error')
    },
    rerender() {
      this.sharedState.tours = this.form.rules.tours
      this.questionsSettingsFormKey = uuidv4()
    },
    updateColor(val) {
      this.new_color = val
    },
    useBottomMargin() {
      this.useBottomMarginValue = true
    },
    disableBottomMargin() {
      this.useBottomMarginValue = false
    },
    showAnimationsVal(val) {
      this.showAnimations = val
    },
    setBackgrounds(val) {
      this.slideBackgrounds = val
    },
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    // subTypes () {
    //   if (
    //     this.typeId &&
    //     this.gameTypes[this.typeId] &&
    //     Object.keys(this.gameTypes[this.typeId].subType.data).length > 0
    //   ) {
    //     return this.gameTypes[this.typeId].subType
    //       ? this.gameTypes[this.typeId].subType.data
    //       : []
    //   }
    //   return []
    // },
    // gameTopics () {
    //   if (
    //     this.typeId &&
    //     this.gameTypes[this.typeId] &&
    //     Object.keys(this.gameTypes[this.typeId].topics.data).length > 0
    //   ) {
    //     return this.gameTypes[this.typeId].topics.data
    //   }
    //   if (
    //     this.typeId &&
    //     this.subTypeId &&
    //     this.gameTypes[this.typeId].subType.data[this.subTypeId] &&
    //     Object.keys(
    //       this.gameTypes[this.typeId].subType.data[this.subTypeId].topics.data
    //     ).length > 0
    //   ) {
    //     return this.gameTypes[this.typeId].subType.data[this.subTypeId].topics
    //       .data
    //   }
    //   return []
    // },
    isAdmin() {
      return this.$store.state.auth.user.role === 'admin'
    },
  },
}
</script>

<style scoped>
@tailwind utilities;

th {
  @apply text-left p-2 bg-white font-sans text-sm border-b border-r border-grey-light;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  font-size: 20px;
}

.brand-checkbox {
  transition: all 0.3s ease-in;
}
</style>
