<template>
  <div class="flex py-3">
    <div class="numbers flex mr-2 mt-4">
      <p class="bg-brand contact-list-handle cursor-move my-auto circle text-white">{{animation.orderNumber}}</p>
    </div>
    <animation-cell :template_id="template_id"
                    :tourMedia="tourMedia"
                    v-for="(item, key) in items"
                    :key="item.collection_name"
                    :item="item"
                    @cellUpdated="updateItem(key, $event)"
    ></animation-cell>
  </div>
</template>
<script>
import AnimationCell from './animation-cell';
const uuidv4 = require('uuid/v4');

export default {
  name: 'Animation',
  components: { AnimationCell },
  props: ['animation', 'tourMedia', 'template_id'],
  data () {
    return {
      'items': [],
    }
  },
  created () {
    this.items = this.animation.items
  },
  methods: {
    updateItem (key, params) {
      this.items[key] = params
      this.$emit('itemsUpdated', this.items)
    },
  }
}
</script>
<style scoped>
@tailwind utilities;
.numbers .circle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  font-size: 23px;

}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  font-size: 20px;
}
.set-new-animation {
  position: absolute;
  z-index: 50;
}
</style>
