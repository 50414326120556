<template>
  <div v-if="isCategoriesSet" class="mt-2">
    <label class="font-semibold text-brand mt-2">{{ setLabel(layer) }}</label>
    <div class="w-full mt-1 flex flex-col">
      <select v-model="selectedCategoryIndex" class="w-full p-1 border border-brand-light rounded">
        <option v-for="(type, key) in categories" :key="key" :value="key"
                class="bg-brand rounded text-white hover:bg-brand-dark">{{ type.name }}
        </option>
      </select>
      <party-categories-selector
          v-if="selectedCategory && selectedCategory.children && Object.values(selectedCategory.children).length > 0"
          :categories="selectedCategory.children" :layer="1"
          @input="provideSelectedCategory"
          :value="currentCategoryIndex"
      />
    </div>
  </div>

</template>

<script>
export default {
  name: 'PartyCategoriesSelector',
  props: {
    categories: Object,
    layer: Number,
    value: Number,
  },
  data() {
    return {
      currentCategoryIndex: this.value,
      selectedCategory: null,
      selectedCategoryIndex: null,
    }
  },
  created() {
    if (this.value) {
      console.log(this.value, this.categories[this.value]);
      if (this.categories[this.value]) {
        console.log('t');
        this.selectedCategoryIndex = this.value;
      } else {
        console.log('g');
        Object.values(this.categories).forEach((i, key) => {
          if (i.children && Object.values(i.children).length > 0 && i.children[this.value]) {
            this.selectedCategoryIndex = i.id
          }
        })
      }
    }
  },
  watch: {
    selectedCategoryIndex: {
      handler (val) {
        this.selectCategory(val)
      },
      deep: true,
    },
  },
  methods: {
    setLabel(index) {
      switch (index) {
        case 0 :
          return 'Категория'
        default:
          return 'Тематика'
      }
    },
    selectCategory(val) {
      val = parseInt(val);
      this.selectedCategory = this.categories[val]
      this.$emit('input', val)
    },
    provideSelectedCategory(val) {
      this.$emit('input', val)
    },
  },
  computed: {
    isCategoriesSet() {
      return Object.values(this.categories).length > 0
    }
  }
}
</script>