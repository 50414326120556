<template>
  <div class="flex flex-col p-4">
    <p @click="showAnimations=!showAnimations" class="inline-block cursor-pointer text-xl text-brand">Кастомизация фонов по вопросам
      <font-awesome-icon v-if="showAnimations"
                         :icon="['fal', 'angle-up']">
      </font-awesome-icon>
      <font-awesome-icon v-if="!showAnimations"
                         :icon="['fal', 'angle-down']"></font-awesome-icon>
    </p>
    <div v-if="slideBackgrounds" v-show="showAnimations">
      <animation v-for="(animation, key) in slideBackgrounds"
                 :key="animation.id"
                 :template_id="template_id"
                 :tourMedia="tourMedia"
                 :animation="animation"
                 @itemsUpdated="updateAnimation(key, $event)">
      </animation>
      <div class="numbers pr-3">
        <button type="button"
                class="no-underline circle bg-brand-red text-white pb-2"
                @click.prevent="addAnimation()">+
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import debounce from 'lodash/debounce'
import axios from 'axios'
import Animation from './animation'

const uuidv4 = require('uuid/v4')

export default {
  name: 'SlideBackgrounds',
  components: { Animation },
  props: ['template_id'],
  data () {
    return {
      slideBackgrounds: [],
      tourMedia: [],
      showAnimations: false,
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      axios.get(`/api/templates/${this.template_id}/slide-backgrounds`)
          .then(({ data }) => {
            console.log(data);
            this.slideBackgrounds = data.body ? data.body : []
            this.tourMedia = data.media
            this.$emit('setBackgrounds', this.slideBackgrounds)
          })
    },
    addAnimation () {
      let id = uuidv4()
      let orderNumber = this.slideBackgrounds.length + 1
      let items = [
        {
          'name': 'Фон',
          'collection_name': `slide_backgrounds_${id}_background`,
        },
        {
          'name': 'Видео до вопроса (при необходимости)',
          'collection_name': `slide_backgrounds_${id}_video`,
        }
      ]
      this.slideBackgrounds.push({
        id: id,
        items: items,
        orderNumber: orderNumber
      })
      this.save(this.slideBackgrounds)
    },
    updateAnimation (key, params) {
      this.slideBackgrounds[key].items = params
      if ((!this.slideBackgrounds[key].items || this.slideBackgrounds[key].items.length === 0)) {
        this.dropAnimation(this.slideBackgrounds[key].id)
      }
      this.save(this.slideBackgrounds)
    },
    dropAnimation (id) {
      this.slideBackgrounds = this.slideBackgrounds.filter(function (obj) {
        return obj.id !== id
      })
      this.save(this.slideBackgrounds)
    },
    save: debounce(function (slideBackgrounds) {
      axios.patch(`/api/templates/${this.template_id}/slide-backgrounds`, { 'slide_backgrounds': slideBackgrounds }).then(() => {
        this.fetchData()
      })
    }, 1000),
  },
}
</script>
<style scoped>
@tailwind utilities;

.numbers .circle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  font-size: 25px;

}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  font-size: 20px;
}
</style>
