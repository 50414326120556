<template>
    <div class="flex py-3">
          <OnClickOutside :do="close">

          <div class="fle">
          <SortableHandler >
            <div ref="why_not"  @click.stop="openPopup" class="numbers flex">
              <p :class="circleType"  class="contact-list-handle cursor-move my-auto circle text-white">{{animation.type === "advertisement" ? 'А' :
                                                                                                          animation.type === "result" ? 'T' : animation.displayValue}}  </p>
              <p class="circle my-auto">{{displayedTourNumber}}</p>
            </div>

          </SortableHandler>
          <div ref="because_no" class=" bg-white border border-brand-dark py-3 mb-4 ml-2 rounded z-50" v-if="animation.type !== 'tour'"
              v-show="isOpen">
                  <p @click="deleteTour()" class="font-semibold hover:text-white hover:bg-brand-dark cursor-pointer px-2 py-1">Удалить тур</p>
                </div>
          </div>
         </OnClickOutside>

        <animation-cell :template_id="template_id"
                :tourMedia="tourMedia"
                v-for="(item, key) in items"
                :key="item.collection_name"
                :animationType="animation.type"
                :item="item"
                @cellUpdated="updateItem(key, $event)"
                @cellDropped="dropItem(key)"
                @cellClear="clearItem(key)"
                class="border-r border-grey-dark"
        ></animation-cell>
        <button type="button"
                v-if="animation.type === 'custom' || animation.type === 'tour'"
                class="no-underline my-auto circle bg-white border-brand border-2 ml-4 text-brand align-middle outline-none px-2 pb-3"
                @click.prevent="addItem()">+
        </button>
    </div>
</template>
<script>
import AnimationCell from './animation-cell'
import SortableHandler from './sortable_handler.vue'
import OnClickOutside from '@/components/OnClickOutside'
import Popper from 'popper.js'

const uuidv4 = require('uuid/v4')

export default {
  name: 'Animation',
  components: { AnimationCell, SortableHandler, OnClickOutside },
  props: ['animation', 'tourMedia', 'template_id', 'idx', 'rules'],
  inject: ['sortableListItemClass'],
  data () {
    return {
      'items': [],
      isOpen: false,
    }
  },
  mounted () {
    this.$el.classList.add(this.sortableListItemClass)
  },
  created () {
    this.items = this.animation.items
  },
  methods: {
    addItem () {
      this.items.push({
        'name': '',
        'collection_name': uuidv4(),
        'settings': {
          'type': '',
          'properties': {
            'link': '',
          },
        },

      })
      this.$emit('itemsUpdated', this.items)
    },
    updateItem (key, params) {
      this.items[key] = params
      this.$emit('itemsUpdated', this.items)
    },
    clearItem (key) {
      this.items[key] = {
        'name': '',
        'collection_name': uuidv4(),
        'settings': {
          'type': '',
          'properties': {
            'link': '',
          },
        },

      }
      this.$emit('itemsUpdated', this.items)
    },

    dropItem (key) {
      this.items.splice(key, 1)
      this.$emit('itemsUpdated', this.items)
    },
    openPopup () {
      if (this.isOpen) {
        return
      }
      this.$emit('close_popups')
      this.isOpen = true
      this.$nextTick(() => {
        this.setupPopper()
      })
    },
    setupPopper () {
      if (this.popper === undefined) {
        this.popper = new Popper(this.$refs.why_not, this.$refs.because_no, {
          placement: 'right',
        })
      } else {
        this.popper.scheduleUpdate()
      }
    },
    close () {
      if (!this.isOpen) {
        return
      }
      this.isOpen = false
    },
    deleteTour () {
      this.$emit('deleteTour', { idx: this.idx })
    },
  },
  computed: {
    circleType () {
      return {
        'bg-brand': this.animation.type === 'tour',
        'bg-brand-red ': this.animation.type === 'custom',
        'bg-indigo': this.animation.type.includes('result'),
        'bg-green-darker': this.animation.type === 'advertisement',
        'bg-yellow-dark': this.animation.type === 'random',
        'bg-orange-light': this.animation.type === 'team-representation',
        'bg-yellow': this.animation.type === 'tour-selection',
        'bg-yellow-light': this.animation.type === 'tour-selection-result',
        'bg-pink-dark': this.animation.type === 'record-review-link',
        'bg-blue': this.animation.type === 'twin',
        'bg-blue-light': this.animation.type === 'twin-timer',
        'bg-purple': this.animation.type === 'multyplayer-results',
      }
    },
    displayedTourNumber() {
      let filtered = this.rules && this.rules.tours ? this.rules.tours.filter((i) => i.id == this.animation.displayValue) : []
      return filtered && filtered.length > 0 ? filtered[0].displayed_number : Number.isInteger(this.animation.displayValue) ? this.animation.displayValue : null
    },
  },
  render () {
    return this.$slots.default[0]
  },
}
</script>
<style scoped>
    @tailwind utilities;

    .numbers .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 100%;
        width: 35px;
        height: 35px;
        font-size: 23px;

    }

    .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        font-size: 20px;
    }
    .set-new-animation {
      position: absolute;
      z-index: 50;
    }
</style>
