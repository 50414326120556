<template>
    <tr class="w-full hover:bg-grey-lightest my-1">
        <td>
            <div class="brand-checkbox my-auto mx-auto">
                <input class="" :checked="checked" type="checkbox" :id="`ch-${gameQuestion.id}`"/>
                <label :for="`ch-${gameQuestion.id}`" @click="$emit('selectQuestion')"></label>
            </div>
        </td>
        <td v-text="index + 1 "></td>
        <td>
            <radio-modal @updated="setGenerationTypeStateBoolean"
                          :states="questionStateType" :font-awe-icon="'arrows-alt-h'"
                          :generationType="generationType"
                          :message="'Тип генерации вопроса'"></radio-modal>
        </td>
        <td class="referenceElement cursor-pointer">
            <tag-description :key="gameQuestion.id" @tagSelected="addTagInfo($event)" :questionId="gameQuestion.id"
                             :selectedTags="gameQuestion.tags.slide_type_id" tagName="slide_type_id"
                             :tags="tags.slide_type_id"
                             class="flex onBottomPopper"></tag-description>
        </td>
        <td class="referenceElement cursor-pointer"
            v-if="tagColumns && tagColumns.complexity.required && tagColumns.complexity.value">
            <tag-description :key="gameQuestion.id" @tagSelected="addTagInfo($event)" :questionId="gameQuestion.id"
                             :selectedTags="gameQuestion.tags.complexity" tagName="complexity" :tags="tags.complexity"
                             class="flex onBottomPopper"></tag-description>
        </td>
        <td class="referenceElement cursor-pointer"
            v-if="tagColumns && tagColumns.question_type.required && tagColumns.question_type.value">
            <tag-description :key="gameQuestion.id" @tagSelected="addTagInfo($event)" :questionId="gameQuestion.id"
                             :selectedTags="gameQuestion.tags.question_type" tagName="question_type"
                             :tags="tags.question_type"
                             class="flex onBottomPopper"></tag-description>
        </td>
        <td class="referenceElement cursor-pointer"
            v-if="tagColumns && tagColumns.theme.required && tagColumns.theme.value">
            <tag-description :key="gameQuestion.id" @tagSelected="addTagInfo($event)" :questionId="gameQuestion.id" :selectedTags="gameQuestion.tags.theme"
                             :tags="tags.theme" tagName="theme"
                             class="flex onBottomPopper"></tag-description>
        </td>
        <td class="referenceElement cursor-pointer"
            v-if="tagColumns && tagColumns.includedRegions.required && tagColumns.includedRegions.value">
            <tag-description :key="gameQuestion.id" @tagSelected="addTagInfo($event)" :questionId="gameQuestion.id" :selectedTags="gameQuestion.tags.includedRegions"
                             :tags="tags.includedRegions" tagName="includedRegions"
                             class="flex onBottomPopper"></tag-description>
        </td>
        <td class="referenceElement cursor-pointer"
            v-if="tagColumns && tagColumns.hit.required && tagColumns.hit.value">
            <tag-description :key="gameQuestion.id" @tagSelected="addTagInfo($event)" :questionId="gameQuestion.id" :selectedTags="gameQuestion.tags.hit"
                             :tags="tags.hit" tagName="hit"
                             class="flex onBottomPopper"></tag-description>
        </td>
        <td class="referenceElement cursor-pointer"
            v-if="tagColumns && tagColumns.direction.required && tagColumns.direction.value">
            <tag-description :key="gameQuestion.id" @tagSelected="addTagInfo($event)" :questionId="gameQuestion.id" :selectedTags="gameQuestion.tags.direction"
                             :tags="tags.direction" tagName="direction"
                             class="flex onBottomPopper"></tag-description>
        </td>
        <td class="referenceElement cursor-pointer"
            v-if="tagColumns && tagColumns.musical_answer_type.required && tagColumns.musical_answer_type.value">
            <tag-description :key="gameQuestion.id" @tagSelected="addTagInfo($event)" :questionId="gameQuestion.id"
                             :selectedTags="gameQuestion.tags.musical_answer_type" :tags="tags.musical_answer_type"
                             tagName="musical_answer_type"
                             class="flex onBottomPopper"></tag-description>
        </td>
        <td class="referenceElement cursor-pointer"
            v-if="tagColumns && tagColumns.music_region.required && tagColumns.music_region.value">
            <tag-description :key="gameQuestion.id" @tagSelected="addTagInfo($event)" :questionId="gameQuestion.id"
                             :selectedTags="gameQuestion.tags.music_region" :tags="tags.music_region"
                             tagName="music_region"
                             class="flex onBottomPopper"></tag-description>
        </td>
        <td class="referenceElement cursor-pointer"
        v-if="tagColumns && tagColumns.is_special.required && tagColumns.is_special.value">
        <tag-description :key="gameQuestion.id" @tagSelected="addTagInfo($event)"
                         :questionId="gameQuestion.id" :selectedTags="gameQuestion.tags.is_special"
                         :tags="tags.is_special" tagName="is_special"
                          class="flex onBottomPopper">

        </tag-description>
        </td>
        <td class="referenceElement cursor-pointer"
            v-if="tagColumns && tagColumns.picture.required && tagColumns.picture.value">
            <tag-description :key="gameQuestion.id" @tagSelected="addTagInfo($event)" :questionId="gameQuestion.id" :selectedTags="gameQuestion.tags.picture"
                             :tags="tags.picture" tagName="picture"
                             class="flex onBottomPopper"></tag-description>
        </td>
        <td class="referenceElement cursor-pointer"
            v-if="tagColumns && tagColumns.blitz.required && tagColumns.blitz.value">
            <tag-description :key="gameQuestion.id" @tagSelected="addTagInfo($event)" :questionId="gameQuestion.id" :selectedTags="gameQuestion.tags.blitz"
                             :tags="tags.blitz" tagName="blitz"
                             class="flex onBottomPopper"></tag-description>
        </td>
        <td class="referenceElement cursor-pointer"
            v-if="tagColumns && tagColumns.company_type.required && tagColumns.company_type.value">
            <tag-description :key="gameQuestion.id" @tagSelected="addTagInfo($event)" :questionId="gameQuestion.id" :selectedTags="gameQuestion.tags.company_type"
                             :tags="tags.company_type" tagName="company_type"
                             class="flex onBottomPopper"></tag-description>
        </td>
        <td class="referenceElement cursor-pointer">
            <font-awesome-icon ref="button" @click.prevent="replace(gameQuestion.id, 'special')"
                               class="text-base" :icon="['fas', 'arrows-alt-h']"
                               :class="{'text-black': generationType === 'special', 'text-grey':generationType !== 'special'}"></font-awesome-icon>
        </td>
        <td class="referenceElement cursor-pointer text-center" v-text="specialQuestionId || null"></td>
        <td class="referenceElement cursor-pointer text-center">
          <multi-optional-radio-modal @updated="setSlideBackgroundUuid"
                       :options="slideBackgrounds" :font-awe-icon="'film'"
                       :selectedOption="slideBackgroundUuid"
                       :message="'Фон слайда'"
                       :valuePropertyName="'id'"
                       :namePropertyName="'orderNumber'"
          >
          </multi-optional-radio-modal>
        </td>
        <td>
            <a href="" @click.prevent="redirectEye" title="Посмотреть">
                <font-awesome-icon class="text-base" :class="{'text-black': specialQuestionId, 'text-grey':!specialQuestionId}" :icon="['fas', 'eye']"></font-awesome-icon>
            </a>
        </td>
        <portal :to="`direct-replace-question-${gameQuestion.id}`">
            <question-replace :question-id="gameQuestion.id" :is-for-template="true" :tour-id="tourId"
                              :game-type-id="gameTypeId" :game-topic-id="gameTopicId"
                              @selected="setSpecialQuestionId"
                              @updated="onDirectReplace">
            </question-replace>
        </portal>
        <td class="border-r-0 ">
            <a href="" class="text-grey-darkest no-underline hover:text-grey"
               @click.prevent="remove(gameQuestion.id)">
                <trash></trash>
            </a>
        </td>
    </tr>
</template>

<script>
import Trash from '../../icons/trash'
import TagDescription from './tag-description'
import RadioModal from '../../components/popperModals/radioModal'
import MultiOptionalRadioModal from '../../components/popperModals/multiOptionalRadioModal'
import QuestionReplace from '../games/game/questionReplace'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'TemplateQuestion',
  components: { QuestionReplace, RadioModal, MultiOptionalRadioModal, TagDescription, Trash },
  props: ['question', 'tagColumns', 'checked', 'tags', 'index', 'parentTourId', 'gameTypeId', 'gameTopicId', 'slideBackgrounds'],

  data () {
    return {
      showTagComplexity: false,
      selectedTags: {},
      selectedQuestions: [],
      generationType: 'random',
      on_loading: false,
      specialQuestionId: '',
      slideBackgroundUuid: ''
    }
  },
  created () {
    if (this.gameQuestion.tags) {
      this.selectedTags = cloneDeep(this.gameQuestion.tags)
    }
    this.slideBackgroundUuid = this.gameQuestion.slideBackgroundUuid
    if (['random', 'static', 'special'].includes(this.gameQuestion.generationType)) {
      this.generationType = this.gameQuestion.generationType
      this.specialQuestionId = this.gameQuestion.specialQuestionId

    } else this.setGenerationTypeStateBoolean(this.generationType)
  },
  watch: {
    selectedTags: {
      handler (val) {
        this.$emit('tagsSelected', val)
      },
      deep: true,
    },
  },
  methods: {
    remove (id) {
      this.$emit('removeQuestion', [id])
    },
    addTagInfo (val) {
      console.log('question', val)
      this.$set(this.selectedTags, Object.keys(val)[0], val[Object.keys(val)[0]])
    },
    setGenerationTypeStateBoolean (state) {
      if (this.generationType !== 'special') {
        this.specialQuestionId = ''
        this.$emit('selected', this.specialQuestionId)
      }
      this.generationType = state
      this.$emit('generationTypeSelected', this.generationType)
    },
    setSpecialQuestionId (id) {
      this.specialQuestionId = id
      this.$emit('selected', this.specialQuestionId)
    },
    setSlideBackgroundUuid (id) {
      this.slideBackgroundUuid = id
      this.$emit('backgroundSelected', this.slideBackgroundUuid)
    },
    replace (id) {
      if (this.generationType !== 'special') {
        return null
      } else {
        this.modalHolder.open(`direct-replace-question-${id}`, 'Замена вопроса')
      }
    },
    onDirectReplace () {
      this.on_loading = false
      this.$emit('replaced')
    },
    redirectEye () {
      if (!this.specialQuestionId) {
        return
      }
      this.$router.push({ name: 'question', params: { questionId: this.specialQuestionId } })
    },
  },
  computed: {
    tour () {
      return this.$route.params.tour
    },
    tourId () {
      return this.parentTourId ? this.parentTourId : this.$route.params.tour
    },
    questionStateType () {
      return {
        random: 'Случайный',
        static: 'Строгий',
        special: 'Спец.игра',
      }
    },
    gameQuestion () {
      return this.question
    },
  },
}

</script>

<style scoped>
    @tailwind utilities;

    td {
        @apply text-left
        p-2 bg-white font-sans text-sm border-b border-r border-grey-light;
    }
</style>
