<template>
  <div>
    <div class="flex flex-col mx-4 mt-4">
      <p @click="showColorPicker=!showColorPicker" class="inline-block cursor-pointer text-xl text-brand" style="width: fit-content;">Кастомизация цветов
        <font-awesome-icon v-if="showColorPicker"
                           :icon="['fal', 'angle-up']">
        </font-awesome-icon>
        <font-awesome-icon v-if="!showColorPicker"
                           :icon="['fal', 'angle-down']"></font-awesome-icon>
      </p>
      <div v-if="showColorPicker" class="flex flex-col mt-8">
        <div class="flex flex-row mt-8">
          <div class="flex flex-row">
            <p class="text-lg text-brand mr-36">Бар</p>
            <div class="flex flex-col">
              <div class="relative colorpicker">
                <color-picker-item @useBottomMargin="useMargin" @disableBottomMargin="$emit('disableBottomMargin')"
                                   :titleText="'Цвет фона'" :custom_design="custom_design.bars.background" @input="inputView($event, 'background', custom_design.bars)">
                </color-picker-item>
              </div>
              <div class="relative colorpicker mt-4">
                <color-picker-item @useBottomMargin="useMargin" @disableBottomMargin="$emit('disableBottomMargin')"
                                   :titleText="'Цвет текста'" :custom_design="custom_design.bars.text" @input="inputView($event, 'text', custom_design.bars)">
                </color-picker-item>
              </div>
              <div class="relative colorpicker mt-4">
                <color-picker-item @useBottomMargin="useMargin" @disableBottomMargin="$emit('disableBottomMargin')"
                                   :titleText="'Цвет обводки'" :custom_design="custom_design.bars.border" @input="inputView($event, 'border', custom_design.bars)">
                </color-picker-item>
              </div>
            </div>
          </div>
          <div class="flex flex-row">
            <p class="text-lg text-brand mr-6 ml-12">Вопрос с 4 вариантами</p>
            <div class="flex flex-col">
              <div class="relative colorpicker">
                <color-picker-item @useBottomMargin="useMargin" @disableBottomMargin="$emit('disableBottomMargin')"
                                   :titleText="'Цвет фона'" :custom_design="custom_design.fourVars.background" @input="inputView($event, 'background', custom_design.fourVars)">
                </color-picker-item>
              </div>
              <div class="relative colorpicker mt-4">
                <color-picker-item @useBottomMargin="useMargin" @disableBottomMargin="$emit('disableBottomMargin')"
                                   :titleText="'Цвет текста'" :custom_design="custom_design.fourVars.text" @input="inputView($event, 'text', custom_design.fourVars)">
                </color-picker-item>
              </div>
              <div class="relative colorpicker mt-4">
                <color-picker-item @useBottomMargin="useMargin" @disableBottomMargin="$emit('disableBottomMargin')"
                                   :titleText="'Цвет обводки'" :custom_design="custom_design.fourVars.border" @input="inputView($event, 'border', custom_design.fourVars)">
                </color-picker-item>
              </div>
            </div>
          </div>
        </div>
        <div class="flex mt-12">
          <div class="flex flex-row mr-20">
            <p class="text-lg text-brand mr-6" style="magin-left: 100px">Выделение текста</p>
            <div class="flex flex-col">
              <div class="relative colorpicker">
                <color-picker-item @useBottomMargin="useMargin" @disableBottomMargin="$emit('disableBottomMargin')"
                                  :titleText="'Цвет'" :custom_design="custom_design.highlighted.text" @input="inputView($event, 'text', custom_design.highlighted)">
                </color-picker-item>
              </div>
            </div>
          </div>
        </div>

        <div class="flex mt-12 mb-12">
          <div class="flex flex-row mr-20">
            <p class="text-lg text-brand mr-6" style="magin-left: 100px">Выгрузки соцсетей</p>
            <div class="flex flex-col">
              <div class="relative colorpicker">
                <color-picker-item @useBottomMargin="useMargin" @disableBottomMargin="$emit('disableBottomMargin')"
                                  :titleText="'Цвет фона шапки'" :custom_design="custom_design.soc_network_export.header_bg" @input="inputView($event, 'header_bg', custom_design.soc_network_export)">
                </color-picker-item>
              </div>
              <div class="relative colorpicker mt-4">
                <color-picker-item @useBottomMargin="useMargin" @disableBottomMargin="$emit('disableBottomMargin')"
                                  :titleText="'Цвет текста шапки'" :custom_design="custom_design.soc_network_export.header_text" @input="inputView($event, 'header_text', custom_design.soc_network_export)">
                </color-picker-item>
              </div>
              <div class="relative colorpicker mt-4">
                <color-picker-item @useBottomMargin="useMargin" @disableBottomMargin="$emit('disableBottomMargin')"
                                  :titleText="'Цвет текста строки'" :custom_design="custom_design.soc_network_export.row_text" @input="inputView($event, 'row_text', custom_design.soc_network_export)">
                </color-picker-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'
import OnClickOutside from '@/components/OnClickOutside'
import ColorPickerItem from '@/pages/game_templates/color-picker-item.vue'
export default {
  name: 'color-picker',
  props: ['custom_design', 'useBottomMargin'],
  components: { ColorPickerItem, OnClickOutside, 'chrome-picker': Chrome },

  data () {
    return {
      new_color: '#fffff',
      message: 'hello',
      updateValue: '',
      hex: '',
      showColorPicker: false,
    }
  },

  created () {

  },

  methods: {
    inputView (input, view, parentView) {
      this.$set(parentView, view, input)
    },
    useMargin () {
      setTimeout(() => { this.$emit('useBottomMargin') }, 1)
    },
  },
}
</script>

<style scoped>
.colorpicker {
  max-width: 225px;
}
</style>
