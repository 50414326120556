<template>
    <div class="w-full px-4">
        <table class="w-full h-full" v-if="loaded">
            <thead>
            <tr>
                <th>№</th>
                <th>Вопрос</th>
                <th>Повтор</th>
                <th>Подводка</th>
                <th>Ответ</th>
                <th>№ вопроса</th>
                <th>№ пакета</th>
                <th title="Просмотр">
                    <font-awesome-icon class="text-grey text-base" :icon="['fas', 'eye']"></font-awesome-icon>
                </th>
                <th class="border-r-0" title="Замена">
                    <font-awesome-icon class="w-6 my-auto text-grey text-base" :icon="['fas', 'arrows-alt-h']"></font-awesome-icon>
                </th>
            </tr>
            </thead>
            <tbody>
            <game-question v-for="(question, key) in questions" :value="question" :key="key" :is-party="isParty" :game="game"
                           :question="question" @updated="fetchQuestions" @replaced="fetchQuestions" @failed="failAlert"></game-question>
            </tbody>
        </table>
    </div>
</template>

<script>
import Trash from '../../../icons/trash'
import TagSelectPanel from '../../../components/TagSelectPanel'
import GameQuestion from './question'
import axios from 'axios'
import swal from 'sweetalert2'

export default {
  name: 'Questions',
  components: { GameQuestion, TagSelectPanel, Trash },
  props: ['isParty', 'game'],
  computed: {
    id () {
      return this.$route.params.id
    },
    tour () {
      return this.$route.params.tour
    },
    serial_number () {
      return this.$route.params.serial_number
    },
  },
  data () {
    return {
      questions: [],
      loaded: false,
    }
  },
  created () {
    this.fetchQuestions()
  },
  methods: {
    fetchQuestions () {
      axios.get(`/api/game-questions?sort=question_number&filter[game_id]=${this.id}&filter[tour_id]=${this.tour}&filter[serial_number]=${this.serial_number}`).then((data) => {
        this.questions = data.data.data
      })
      this.loaded = true
    },
    failAlert () {
      swal('Ошибка!', 'Не удалось осуществить замену', 'error')
      this.fetchQuestions()
    },
  },
}
</script>

<style scoped>
    @tailwind utilities;

    th {
        @apply text-left
        p-2 bg-white font-sans text-sm border-r border-grey-light;
    }
</style>
