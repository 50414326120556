<template>
    <div v-if="question" class="container mx-auto p-4">
        <question v-model="question" :key="question.id" @save="saveQuestion(question)"></question>
    </div>
</template>

<script>

import Question from './question.vue'
import axios from 'axios'

export default {
  components: {
    Question,
  },

  data () {
    return {
      question: null,
      s_type: {},
    }
  },

  created () {
    this.fetchQuestions()
  },

  computed: {
    questionId () {
      return this.$route.params.questionId
    },
  },

  methods: {
    fetchQuestions () {
      axios.get(`/api/questions/${this.questionId}`).then(({ data }) => {
        this.question = data.data
      })
    },
    saveQuestion (question) {
      axios.put(`/api/questions/${this.questionId}`, question)
    },
  },
}

</script>

<style scoped>
    .numbers .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        font-size: 20px;
    }
</style>
