<template>
    <div class="w-full" v-if="loaded">
        <div class="flex flex-wrap">
            <div class="pt-2 w-1/4">

            </div>
            <div class="w-1/2 pt-2 px-2" v-if="isCorpLeague&&(question.tour_id === 1||question.tour_id === 4) ">
                <label class="font-semibold text-brand pb-1">Компании</label>
                <div class="">
                    <search-select
                            v-model="currentValue.tags.company_type"
                            :options="tags.company_type"
                            :filter-function="applySearchFilter"
                    ></search-select>
                </div>
            </div>
            <div class="w-1/2 pt-2 px-2" v-else-if="question.tour_id !== 3 && question.tour_id !== 5 && question.tour_id !== 7">
                <label class="font-semibold text-brand pb-1">Тексты</label>
                <div class="">
                    <search-select
                            v-model="currentValue.tags.theme"
                            :options="tags.theme"
                            :dis="disabled"
                            :filter-function="applySearchFilter"
                    ></search-select>
                </div>
            </div>
            <div class="w-1/2 pt-2 px-2" v-if="question.tour_id === 7">
                <label class="font-semibold text-brand pb-1">Блиц</label>
                <div class="">
                    <search-select
                            v-model="currentValue.tags.blitz"
                            :options="tags.blitz"
                            :filter-function="applySearchFilter"
                    ></search-select>
                </div>
            </div>
            <div class="w-1/2 pt-2 px-2" v-if="question.tour_id === 5">
                <label class="font-semibold text-brand pb-1">Картинки</label>
                <div class="">
                    <search-select
                            v-model="currentValue.tags.picture"
                            :options="tags.picture"
                            :disabled="disabled"
                            :filter-function="applySearchFilter"
                    ></search-select>
                </div>
            </div>
            <div class="w-1/2 pt-2 px-2" v-if="question.tour_id === 3">
                <label class="font-semibold text-brand pb-1 ml-4">Музыка</label>
                <div class="">
                    <select v-model="currentValue.tags.musical_answer_type" :disabled="disabled" class="p-1 border-solid border-brand-light border w-full rounded">
                        <option v-for="tag in tags.musical_answer_type"  :key="tag.id" v-text="tag.name" :value="tag.id" class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                    </select>
                </div>
            </div>
            <div class="flex flex-col w-1/4 pt-2 px-2">
                <label class="font-semibold text-brand pb-1">Сложность</label>
                <div class="flex flex-row">
                    <div class="flex flex-row px-1 items-center" v-for="(tag, key) in tags.complexity" v-if="key !== 0">
                        <p class="text-lg mx-1">{{tag.name}}</p>
                        <input type="radio" name="complexity" :value="tag.id" :disabled="disabled" v-model="currentValue.tags.complexity">
                    </div>
                </div>
            </div>

            <div class="w-1/4 pt-2" v-if="question.tour_id !== 3">
                <label class="font-semibold text-brand pb-1">Тип вопроса</label>
                <div class="w-full">
                    <select v-model="currentValue.tags.question_type" :disabled="disabled" class="p-1 border-solid border-brand-light border w-full rounded">
                        <option v-for="tag in tags.question_type" :key="tag.id" v-text="tag.name" :value="tag.id" class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                    </select>
                </div>
            </div>
            <div class="pt-2 flex flex-row pr-2 w-1/2 justify-between">
                <div class="flex flex-col" style="width: 49%">
                    <label class="font-semibold text-brand">Регион +</label>
                    <multi-select class="w-full" :type="'included'" v-model="question.includedRegions" :disabled="disabled" :options="tags.includedRegions"></multi-select>
                </div>
                <div class="flex flex-col" style="width: 49%">
                    <label class="font-semibold text-brand">Регион -</label>
                    <multi-select class="w-full" :type="'excluded'" v-model="question.excludedRegions" :disabled="disabled" :options="tags.excludedRegions"></multi-select>
                </div>
            </div>
            <div v-if="isCorpLeague&&question.tour_id === 4" class="pt-2 flex flex-col justify-between w-32 pr-2">
                    <label class="font-semibold text-brand whitespace-no-wrap">Имя компании</label>
                    <input class="border border-brand-light rounded h-9 px-2" type="text" v-model="question.company_name"/>
            </div>

            <div class="flex flex-row pt-2 justify-start">
                <div class=" flex flex-col">
                    <label for="is_special" class="text-brand-dark font-semibold pr-3 pb-2">Спец.игра</label>
                    <input id="is_special" class="select-none" v-model="question.is_special" :disabled="disabled" type="checkbox"/>
                </div>
                <div class=" flex flex-col">
                  <label for="is_adaptive" class="text-brand-dark font-semibold pr-3 pb-2">Адаптация</label>
                  <input id="is_adaptive" class="select-none" v-model="question.is_adaptive" :disabled="disabled" type="checkbox"/>
                </div>
                <!--<div class="w-3/4 pt-4 -ml-2" v-show="question.is_special">-->
                    <!--<select v-model="currentValue.music_region" class="p-1 border-solid border-brand-light border w-full rounded">-->
                        <!--<option v-for="tag in tags.music_region" :key="tag.id" v-text="tag.name" :value="tag.id" class="bg-brand rounded text-white hover:bg-brand-dark"></option>-->
                    <!--</select>-->
                <!--</div>-->
            </div>
            <hr class="w-full h-2px bg-grey-light -mb-1 mt-3" v-if="question.tour_id === 3">
            <div class="w-1/4 pt-2 pr-2" v-if="question.tour_id === 3">
                <label class="font-semibold text-brand pb-1">Стиль</label>
                <select v-model="currentValue.tags.direction" :disabled="disabled" class="p-1 border-solid border-brand-light border w-full rounded">
                    <option v-for="tag in tags.direction" :key="tag.id" v-text="tag.name" :value="tag.id" class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                </select>
            </div>
            <div class="pl-2 pt-2 flex flex-col w-1/4" v-if="question.tour_id === 3">
                <label class="font-semibold text-brand pl-6">Хит</label>
                <div class="w-full flex flex-row">
                    <div class="flex flex-row mx-3 items-center" v-for="(hit, key) in tags.hit" :key="key" v-if="key !== 0">
                        <label class="font-semibold text-brand pb-1 mx-1" v-text="hit.name"></label>
                        <input name="hit" v-model="currentValue.tags.hit" :value="hit.id" type="radio">
                    </div>
                    <div class="flex px-1 items-center h-full flex-row">
                        <p class="text-lg mx-1" v-text="'--'"></p>
                        <input type="radio" name="hit" :value="tags.hit[0].id" v-model="currentValue.hit">
                    </div>
                </div>
            </div>
            <div class="flex pt-2 flex-col w-1/4" v-if="question.tour_id === 3">
                <label class="font-semibold text-brand pb-2">Локал</label>
                <div class="flex flex-row items-center h-6">
                    <input class="select-none mr-2" v-model="tag_local" :disabled="disabled" type="checkbox"/>
                    <select v-model="currentValue.tags.music_region" v-show="tag_local" :disabled="disabled" class="p-1 border-solid border-brand-light border w-full rounded">
                        <option v-for="tag in musicRegion" :key="tag.id" v-text="tag.name" :value="tag.id" class="bg-brand rounded text-white hover:bg-brand-dark"></option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import clone from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import axios from 'axios'
import SearchSelect from './SearchSelect'
import MultiSelect from '../../components/MultiSelect'

export default {
  name: 'Tags',
  components: { MultiSelect, SearchSelect },
  props: ['value', 'track', 'question', 'dis'],

  data () {
    return {
      selectedBand: null,
      tag_local: false,
      tag_hit: false,
      search: '',
      currentValue: {},
      tags: [],
      loaded: false,
    }
  },

  created () {
    console.dir(this.question)
    this.currentValue = this.question
    this.trackValue = clone(this.track)
    axios.get(`/api/tags`).then((data) => {
      this.tags = data.data
      this.loaded = true
      if (this.currentValue.tour_id.toString() === '3') {
        const musicRegionTag = this.tags.music_region.find(music_region => music_region.name === 'Мир')
        if (!this.currentValue.tags.music_region || this.currentValue.tags.music_region.toString() === musicRegionTag.id.toString()) {
          this.currentValue.tags.music_region = musicRegionTag.id
        } else this.tag_local = true
      }
    })
  },

  watch: {
    trackValue () {
      this.updateByTrack()
    },
    tag_local () {
      if (!this.tag_local) {
        this.currentValue.tags.music_region = this.tags.music_region.find(music_region => music_region.name === 'Мир').id
      }
    },
    tag_hit () {
      this.currentValue.tags.hit = ''
      if (this.tag_hit === false) {
        this.currentValue.tags.hit = 2
      }
      if (this.tag_hit === true) {
        this.currentValue.tags.hit = 1
      }
    },
  },

  computed: {
    musicRegion () {
      return this.tags.music_region.filter(music_region => music_region.name !== 'Мир' && music_region.name !== '-- Не выбрано --')
    },
    disabled () {
      return this.dis
    },
    getQuestion () {
      this.currentValue = this.question
    },

    isCorpLeague () {
      // return true;
      return this.question.game_type.toLowerCase().includes('корпорати') || this.question.sub_type.toLowerCase().includes('корпорати')
    },
  },

  methods: {
    applySearchFilter (search, thems) {
      return thems.filter(them => them.name.toLowerCase().startsWith(search.toLowerCase()))
    },
    updateByTrack: debounce(function () {
      this.$emit('track-updated', this.trackValue)
    }, 500),
  },

}
</script>
