<template>
    <on-click-outside :do="close">
        <div class="relative" :class="{ 'is-active': isOpen }">
            <div v-if="displayedValue" class="numbers flex my-auto ml-1"  ref="button" @click.prevent="switchPopper">
              <p class="bg-brand contact-list-handle cursor-pointer my-auto circle text-white">{{displayedValue}}</p>
            </div>
            <font-awesome-icon v-else ref="button" @click.prevent="switchPopper"
                             class="w-6 mx-auto my-auto text-black text-base"
                             :class="{'text-black': options && options.length, 'text-grey':!options || !options.length}"
                             :icon="['fas', fontAweIcon]"></font-awesome-icon>
            <div ref="panel" v-show="isOpen && options && options.length" class="z-50 mt-6 ml-4 absolute">
                <div class="modal-container p-4 text-sm w-full flex flex-col mx-auto justify-center items-center text-center">
                    <div class="my-auto w-full">{{message}}</div>
                    <div class="flex py-3 flex-col w-full mx-auto">
                        <div class="mr-auto flex flex-wrap" v-for="option in options">
                            <input class="mr-1" :value="option[valuePropertyName]" type="radio" v-model="state">
                            <p v-text="option[namePropertyName]"></p>
                        </div>
                    </div>
                    <a href="" @click.prevent="confirmAction" class="my-auto p-1 bg-brand-red rounded text-sm text-white w-full mb-1 no-underline">
                        Подтвердить
                    </a>
                </div>
            </div>
        </div>
    </on-click-outside>
</template>

<script>
import Popper from 'popper.js'
import OnClickOutside from '../OnClickOutside'

export default {
  name: 'multiOptionalRadioModal',
  components: {
    OnClickOutside,
  },
  props: ['options', 'message', 'fontAweIcon', 'selectedOption', 'valuePropertyName', 'namePropertyName'],
  data () {
    return {
      ext: '',
      isOpen: false,
      state: 'random',
    }
  },
  created () {
    if (this.selectedOption) {
      this.state = this.selectedOption
    }
  },
  beforeDestroy () {
    if (this.popper !== undefined) {
      this.popper.destroy()
    }
  },
  methods: {
    switchPopper () {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$nextTick(() => {
          this.setupPopper()
        })
      }
    },
    close () {
      if (!this.isOpen) {
        return
      }
      this.isOpen = false
    },
    setupPopper () {
      if (this.popper === undefined) {
        this.popper = new Popper(this.$refs.button, this.$refs.panel, {
          placement: 'bottom',
          positionFixed: true,
        })
      } else {
        this.popper.scheduleUpdate()
      }
    },
    confirmAction () {
      this.$emit('updated', this.state)
      this.close()
    },
  },
  computed: {
    displayedValue() {
      if (this.selectedOption) {
        let displayedOption = this.options.filter((item) => {
          return item[this.valuePropertyName] === this.selectedOption
        })
        if (displayedOption && displayedOption.length > 0 && displayedOption[0][this.namePropertyName]) {
          return displayedOption[0][this.namePropertyName]
        }
      }
      return null
    },
  }
}
</script>

<style scoped>

    .modal-container {
        background-color: #fff;
        border-radius: 10px;
        -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
        font-family: Helvetica, Arial, sans-serif;
    }
    .numbers .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 100%;
      width: 18px;
      height: 18px;
      font-size: 14px;
    }
</style>
