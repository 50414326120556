<template>
   <div class="flex-1 mx-2 flex flex-col mt-2">
              <label class="font-semibold text-brand pb-1">Стоимость игры в {{currency}}</label>
              <div class="w-full mt-auto">
                <input
                  type="text"
                  v-model="choosenPrice"
                  class="w-full p-1 border border-brand-light rounded"
                  placeholder="Введите стоимость"
                  @input="$emit('input', +choosenPrice)"
                >
              </div>
            </div>
</template>
<script>
export default {
  name: 'MultiPrice',
  data () {
    return {
      choosenPrice: '',
    }
  },
  props: ['currency', 'value'],
  created () {
    this.choosenPrice = this.value
  },
}
</script>
