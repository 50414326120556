<template>
  <on-click-outside :do="close">
    <div>
      <div class="picker">
        <p class="color-picker-title-text">{{titleText}}</p>
        <div class="fg-color ml-3"/>
        <button class="fg-color-sample border border-grey-dark" @click.prevent="togglePicker" :style="{ background: custom_design.hex }"></button>
      </div>
      <chrome-picker v-if="isOpenPicker" v-model="local_custom_design" @input="$emit('input', local_custom_design)" ></chrome-picker>
    </div>
  </on-click-outside>
</template>

<script>
import { Chrome } from 'vue-color'
import OnClickOutside from '@/components/OnClickOutside'
export default {
  name: 'color-picker-item',
  props: ['custom_design', 'titleText', 'useBottomMargin'],
  components: { OnClickOutside, 'chrome-picker': Chrome },

  data () {
    return {
      isOpenPicker: false,
      local_custom_design: this.custom_design,
    }
  },

  methods: {
    close () {
      this.isOpenPicker = false
      this.$emit('disableBottomMargin')
    },
    togglePicker () {
      this.$emit('useBottomMargin')
      this.isOpenPicker = true
    },
  },
}
</script>

<style scoped>
.vue-color__chrome__alpha-wrap,
.vue-color__chrome__toggle-btn,
.vue-color__chrome__fields-wrap,
.vue-color__chrome__color-wrap {
  display: none !important;
}

.vue-color__chrome__controls {
  align-items: center;
}

.vue-color__chrome__active-color {
  margin-top: 0;
}

.fg-color {
  height: 20px;
  /*flex: 1;*/
}

.vc-chrome{
  transform: translateX(10rem) translateY(-2rem);
  position: absolute;
  z-index: 10;
}

.fg-color-sample {
  display: inline-block;
  width: 20px;
  /*border: none;*/
  border-radius: 3rem;
  outline: none;
}

.picker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.colorpicker {
  max-width: 225px;
}

.color-picker-title-text {
  color: #6a6384;
  font-size: 1.125rem;
  opacity: 0.7;
}
</style>
