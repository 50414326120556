<template>
    <div class="flex flex-col p-4">
        <p class="inline-block cursor-pointer text-xl text-brand">Вопросы</p>
        <div class="flex justify-between text-center -mx-2 mt-4">
            <router-link v-if="tours" v-for="tour in tours" :key="tour.id"
                         :to="{name: 'template_questions', params: {id: id, tour: tour.id, questions: tour.questions}}"
                         class="text-white no-underline px-8 py-2 bg-brand rounded mx-3 hover:bg-brand-light whitespace-no-wrap"
            >{{tour.id}} тур
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
  name: 'TourSwitch',
  props: {
    id: '',
    tours: Array,
  },
}
</script>

<style scoped>
    @tailwind utilities;

    .router-link-active {
        @apply bg-brand-light;
    }

</style>
