<template>
    <form @submit.prevent="save">
        <div class="w-1/2 mb-4 flex">
            <div class="flex">
                <label class="font-semibold text-brand my-auto">Спец. вопрос</label>
                <city-checkbox class="mx-2" v-model="filter.is_special"
                               :element-id="`is-special-filter`"></city-checkbox>
            </div>
        </div>
        <div v-if="loaded">
            <div class="h-88 overflow-x-hidden overflow-y-auto">
                <table class="w-full">
                    <tr v-for="(question, key) in questions" :key=key>
                        <td>
                            <div class="brand-checkbox my-auto mx-auto">
                                <input class="" type="checkbox" :id="`ch-${question.id}`"/>
                                <label :for="`ch-${question.id}`"  @click="selectQuestion(question)"></label>
                            </div>
                        </td>
                        <td>{{question.id}}</td>
                        <td>{{question.question.text}}</td>
                    </tr>
                </table>
            </div>

            <div class="mt-2">
                <button href="#" type="submit" :disabled="sending"
                        class="bg-brand transition  hover:bg-brand-darker text-white rounded py-2 px-4">Подтвердить
                </button>
                <a href="" class="ml-2 no-underline uppercase tracking-wide font-medium text-grey text-sm"
                   @click.prevent="cancel">Отменить
                </a>
            </div>
        </div>
        <spinner class="-mt-2" v-else></spinner>
    </form>
</template>

<script>
import axios from 'axios'
import Spinner from '../../../components/spinner'
import CityCheckbox from '../../city/checkbox'

export default {
  name: 'QuestionReplace',
  components: { CityCheckbox, Spinner },
  props: ['questionId', 'tourId', 'isForTemplate', 'category', 'game', 'gameTypeId', 'gameTopicId', 'foreverReplaced'],
  data () {
    return {
      questions: [],
      selectedQuestion: '',
      loaded: false,
      sending: false,
      gameTypes: {},
      filter: {
        is_special: this.isForTemplate,
      },
    }
  },
  created () {
    axios.get('/partners/game-types')
      .then((data) => {
        this.gameTypes = data.data.data
        this.fetchQuestions()
      })
  },
  methods: {
    save () {
      if (this.isForTemplate) {
        this.$emit('selected', this.selectedQuestion.id)
        this.modalHolder.close()
      } else {
        this.sending = true
        axios.patch(`/api/game-questions/${this.questionId}/replace/direct?questionId=${this.selectedQuestion.id}&forever=${this.foreverReplaced}`).then(
          () => {
            this.sending = false
            this.$emit('updated')
            this.modalHolder.close()
          }
        )
      }
    },
    fetchQuestions () {
      this.loaded = false
      let query = `/api/questions-by-tour?sort=-published_at&filter[published]=true&filter[tour_id]=${this.tourId}&` + this.prepareQuery()
      if (!this.isForTemplate) {
        query = query + `&filter[state][${this.isUsedQuery}]=${this.game.city_id}`
        if (this.game.parent_template && this.game.parent_template.game_type_id) {
          query = query + `&filter[game_type_id]=${this.game.parent_template.game_type_id}`
        }
        else if (this.game.template.game_type_id) {
          query = query + `&filter[game_type_id]=${this.game.template.game_type_id}`
        }
        if (this.game.template.game_topic_id) {
          query = query + `&filter[game_topic_id]=${this.game.template.game_topic_id}`
        }
      } else {
        if (this.gameTypeId) {
          query = query + `&filter[game_type_id]=${this.gameTypeId}`
        }
        if (this.gameTopicId) {
          query = query + `&filter[game_topic_id]=${this.gameTopicId}`
        }
      }
      axios.get(query)
        .then(({ data: response }) => {
          this.questions = response.data
          this.loaded = true
        })
    },
    selectQuestion (question) {
      this.selectedQuestion = question
    },
    cancel () {
      this.modalHolder.close()
    },
    prepareQuery () {
      let response = []
      for (let key in this.filter) {
        response.push(`filter[${key}]=${this.filter[key]}`)
      }
      return response.join('&')
    },
  },
  computed: {
    isUsedQuery () {
      if (this.category === 'MozgoParty') return 'is_not_used_by_party'
      if (this.category === 'ClassicGame') return 'not_played'
      return 'all';
    },
  },
  watch: {
    filter: {
      handler (val) {
        this.fetchQuestions()
      },
      deep: true,
    },
  },
}

</script>

<style scoped>
    @tailwind utilities;

    td {
        @apply .text-left .p-2 .bg-white .font-sans .text-sm .border-b .border-r .border-grey-light;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 12px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #dae1e7;
        border-radius: .25rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #6a6384;
        border-radius: .25rem;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b4b0c1;
    }
</style>
