<template>
    <div class="pr-2 flex justify-start relative">
        <div v-if="cell" class="flex justify-start">
          <button :class="tourMedia[cell.collection_name] ? 'bg-brand text-white' : ''"
            class="p-1 text-center font-semibold border-brand outline-none border rounded w-48 ml-6 mr-4"
            @click.prevent="addAnimation">
            {{item.name || 'Добавить анимацию'}}
        </button>
        </div>
        <!-- <media :model_name="'templates'" :model_id="template_id"
               :media="tourMedia ? tourMedia[cell.collection_name] : ''"
               :collection_name="cell.collection_name" @input="$emit('cellUpdated', cell)"
               class="pl-2 pr-4 mr-12"></media> -->
<!--
        <button type="button"
                v-if="animationType === 'custom'"
                title="clear"
                class="no-underline circle bg-white text-brand-dark flex my-auto pb-2 -ml-1 border border-brand outline-none mr-2"
                @click.prevent="$emit('cellClear')">с
        </button> -->
        <button type="button"
                v-if="isDeletable"
                title="delete"
                class="no-underline circle bg-brand-red text-white flex my-auto pb-2 -ml-1 outline-none mr-2"
                @click.prevent="$emit('cellDropped')">х
        </button>
        <portal  :to="`create-new-animation-${item.collection_name}`">
          <AnimationCustom :model_name="'templates'" :model_id="template_id"
            :media="tourMedia ? tourMedia[cell.collection_name] : ''"
            :collection_name="cell.collection_name"
            :cell_settings="cell.settings"
            :item_name="cell.name"
            :animation_type="animationType"
            @input="$emit('cellUpdated', cell)"
            :key="cell.collection_name"
            @settings_change="settingsChange"></AnimationCustom>
        </portal>
    </div>
</template>
<script>
import Media from '../../../components/media/media'
import Modal from '@/components/Modal'
import AnimationCustom from './animation-type.vue'

const uuidv4 = require('uuid/v4')

export default {
  name: 'AnimationCell',
  components: { Media, AnimationCustom },
  props: ['animationType', 'item', 'tourMedia', 'template_id'],
  data () {
    return {
      'cell': {
        'name': '',
        'collection_name': '',
        'settings': {
          'type': '',
          'properties': {
            'link': '',
          },
        },
      },
    }
  },
  created () {
    if (!this.item.hasOwnProperty('settings')) {
      this.item.settings = {
        'type': 'click_play',
        'properties': {
          'link': '',
        },
      }
    }
    this.cell = this.item
  },
  methods: {
    addAnimation () {
      this.modalHolder.open(`create-new-animation-${this.item.collection_name}`, 'Добавить анимацию')
    },

    settingsChange ($event) {
      this.item.name = $event.name
      this.item.settings.type = $event.type
      if ($event.link.length && $event.type === 'link') {
        this.item.settings.properties.link = $event.link
      }
    },
  },

  watch: {
    item: {
      handler (val) {
        this.$emit('cellUpdated', val)
      },
      deep: true,
    },
  },
  computed: {
    animation_id () {
      return uuidv4()
    },
    isDeletable() {
      const keys = [
          'question_before',
          'question_after',
          'rules',
          'repeat_before',
          'repeat_after',
          'answer_before',
          'answer_after'
      ]
      let systemCell = false
      keys.forEach((item) => {
        if (this.cell.collection_name.includes(item)) {
          systemCell = true
        }
      })
      return !systemCell;
    }
  },
}
</script>
<style scoped>
    @tailwind utilities;

    .numbers .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 100%;
        width: 35px;
        height: 35px;
        font-size: 23px;

    }

    .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        font-size: 20px;
    }
</style>
